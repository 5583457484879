import React, { useContext, useState } from 'react';
import { Collapse, Table, Row, Col } from 'reactstrap';
import { Line } from 'react-chartjs-2'
import Loading from '../../../../loading/Loading';
import { contextApp } from '../../../../App/contextApp';

const Times = (props) => {
    const [collapse, setCollapse] = useState(false);
    const { monthsLabel, dataIncidences, texts } = props;
    const {delivery} = dataIncidences;
    const toggle = () => setCollapse(!collapse);

const {loading, collapseToSS} = useContext(contextApp);

const getTiempo40Min = () => {
    if (!delivery?.didi_tiempo_40 || !delivery?.rappi_tiempo_40 || !delivery?.uber_tiempo_40) return
    var out = delivery.didi_tiempo_40;
    var out2 = delivery.rappi_tiempo_40;
    var out3 = delivery.uber_tiempo_40;

    let result = out.map((val, idx) => val + out2[idx] + out3[idx]).reduce((acc, val) => acc.concat(val), []);

    if(delivery.didi_tiempo_40 && delivery.rappi_tiempo_40 && delivery.uber_tiempo_40){
        if(delivery.didi_tiempo_40.length === 1 && delivery.rappi_tiempo_40.length === 1 && delivery.uber_tiempo_40.length === 1) {
            // result = [null, 0, null];
            result = [result[0]];
        }
    }
    return result;
}

const getTiempo1Hora = () => {
    if (!delivery?.didi_tiempo_1hr || !delivery?.rappi_tiempo_1hr || !delivery?.uber_tiempo_1hr) return
    var out = delivery.didi_tiempo_1hr;
    var out2 = delivery.rappi_tiempo_1hr;
    var out3 = delivery.uber_tiempo_1hr;

    let result = out.map((val, idx) => val + out2[idx] + out3[idx]).reduce((acc, val) => acc.concat(val), []);

    if(delivery.didi_tiempo_1hr && delivery.rappi_tiempo_1hr && delivery.uber_tiempo_1hr){
        if(delivery.didi_tiempo_1hr.length === 1 && delivery.rappi_tiempo_1hr.length === 1 && delivery.uber_tiempo_1hr.length === 1) {
            // result = [null, 0, null];
            result = [result[0]];
        }
    }
    return result;
}

const getMonthsLabel = () => {
    var out = monthsLabel;
    if(monthsLabel.length === 1) {
        out = ['nulo', monthsLabel[0], 'nulo' ];
    }
    return out;
}

const data = {
    labels: getMonthsLabel(),
    datasets:[{
        label: "MÁS DE 40 MINUTOS",
        fill: false,
        lineTension: 0,
        backgroundColor: '#0cf3ae',
        borderColor: '#0cf3ae',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#0cf3ae',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#0cf3ae',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 10,
        data: getTiempo40Min()

    },{
        label: texts.moreThan10,
        fill: false,
        lineTension: 0,
        backgroundColor: '#0cf336',
        borderColor: '#0cf336',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#0cf336',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#0cf336',
        pointHoverBorderColor: '#0cf336',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 20,
        type: 'line',
        data: getTiempo1Hora(),
    },
    


]
}

return (
<div>
    <div className='collapseHeader' onClick={toggle}>  
        <h4 className='emigre-bold text-secondary text-left text-white text-uppercase'>
            <i className="icomoon icon-tiempo text-white ml-3 p-1"></i> {texts.time} <span><svg height="30" width="30" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={collapse ? "css-6q0nyr-Svg" : "css-6q0nyr-Svg closed"}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
        </h4>
    </div>
    <Collapse isOpen={collapse || collapseToSS}>
    {loading ? (
        <Loading />
        ) : null}
        <Row>
            <Col xs={12} className='text-center'>
                <p className="d-inline mx-3 text-center">Más de 40 Minutos<span className="legend badge-box__time-mas-40 ml-2"></span></p>
                <p className="d-inline mx-3 text-center">Más de 1 Hora<span className="legend badge-box__time-mas-60 ml-2"></span></p>
                <Line data={data} legend={{display:false}}></Line>
            </Col>

        </Row>
        <Row>
            <Col xs={12}>
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th scope="row" className="bg-secondary text-uppercase">{texts.time}</th>
                            {monthsLabel && monthsLabel.map((item, index) => {
                                return <th className="text-center bg-secondary" key={index}>{item}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-uppercase">Más de 40 minutos</td>
                            {getTiempo40Min() && getTiempo40Min().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">Más de 1 hora</td>
                            {getTiempo1Hora() && getTiempo1Hora().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Collapse>
</div>
);
}

export default Times;