/* eslint-disable array-callback-return */

import React, {useState, useEffect, useContext} from 'react';
import './Services.scss';
import Layout from '../../layout/layout';
import TableATC from './TableATC/TableATC';
import { Container, Row, Col, Card } from 'reactstrap';
import Select from 'react-select';
import { components } from 'react-select'
import HttpHandlerServices from '../../../services/httpService';

import { contextApp } from "../../App/contextApp";
import language from './language.json';


const Option = (props) => {
    return(
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    /> {" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}


const Services = (props) =>{
    const [dataATC, setDataATC] = useState([]);
    const [filteredDataATC, setFilteredDataATC] = useState([]);

    const [optionsFran, setOptionsFran] = useState([]);
    const [optionsStates, setOptionsStates] = useState([]);
    const [optionsCities, setOptionsCities]= useState([]);
    const [optionsSuc, setOptionsSuc] = useState([]);

    const [optionSelected, setOptionSelected] = useState({
        franchiseSelected: null,
        statesSelected: null,
        localitySelected: null,
        storesSelected: null,
      });
    
    const [sendData, setSendData] = useState({
            franchise_ids: '0',
            state_ids: '0',
            locality_ids: '0',
            stores_ids: '0'
    })

    const [hasPrevVal, setHasPrevVal] = useState({
        franchise: 0,
        state: 0,
        locality: 0,
        store: 0
    });

    const [fullData, setFullData] = useState([]);

    const {currentLanguage} = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])


    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? '#ff7534'
              : null,
            color: isDisabled
              ? 'white'
              : isSelected,
            cursor: isDisabled ? 'not-allowed' : 'white',
      
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && (isSelected ? 'white' : '#ff7534'),
            },
          };
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor:'#ff7534',
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: 'white',
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: 'white',
          ':hover': {
            backgroundColor: data.color,
            color: '#dc3545',
          },
        }),
    };

    const sendFiltersATC = () => {
        const httpHandlerServices = new HttpHandlerServices();
        const getDataATC = '/showconversations';
        httpHandlerServices.postMethod(getDataATC, sendData).then(
            async(res) => {
                const data = await res;
                if(data.message){
                    setDataATC([]);
                }
                setDataATC(data);
                setFilteredDataATC(data);
            }
        )
    }

    const getFranquises = (listFranchise = []) => {

        const endpoit = '/showfranchise';
        const httpHandlerServices = new HttpHandlerServices();
        httpHandlerServices.getMethod(endpoit).then(
            async res => {
                const data = await res;
                const array = [];
                data.map(item => {
                    const obj = {
                        value: item.id,
                        label: item.franchise
                    }

                    array.push(obj)
                    
                })
                setOptionsFran(array);
                
            },
            async error => {
                const err =  await error;
            }  
        );
    }

    // const getStatesList = (listStates = []) => {
    //     const httpHandlerServices = new HttpHandlerServices();
    //     const url = `/showstate/0`;
    //     httpHandlerServices.getMethod(url).then(
    //         async(res) => {
    //             const data = await res;
             
    //             if(data){
    //                 const array = []
    //                 const arrStates = [];
    //                 if(listStates.length === 0) {
    //                     data.map(ele => {
    //                         const item = {
    //                             label: ele.state,
    //                             value: ele.id
    //                         }
    //                         array.push(item);
    //                     });
    //                     setOptionsStates(array);
    //                 } else {
    //                     const result = listStates.map(e => data.filter(ex => e === ex.id))
    //                     result.forEach(e => e.forEach(ex => arrStates.push({label: ex.state, value: ex.id})))
    //                     setOptionsStates(arrStates)
    //                     // setOptionsStates()
    //                 }
    //             }
                
    //         }, 
    //         (error) => {

    //         }
    //     )
    // }
    const getStatesList = (listStates = []) => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showstate/0`;
        httpHandlerServices.getMethod(url).then(
            async(res) => {
                const data = await res;
             
                if(data){
                    const array = []
                        data.map(ele => {
                            const item = {
                                label: ele.state,
                                value: ele.id
                            }
                            array.push(item);
                        });
                        setOptionsStates(array);
                        // setOptionsStates()
                }
                
            }, 
            (error) => {

            }
        )
    }

    const getLocalityList = () => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showlocality/0`;

        httpHandlerServices.getMethod(url).then(
            async(res) => {
                const data = await res;
    
                if(data){
                    const array = []
                        data.map(ele => {
                            const item = {
                                label: ele.locality,
                                value: ele.id
                            }
                            array.push(item);
                        });
                        setOptionsCities(array);
                }
                
            }, 
            (error) => {

            }
        )
    }

    const getStores = (listSuc = []) => {

        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showstores`;

        httpHandlerServices.getMethod(url).then(
            async(res) => {
                const data = await res;
                if(data){
                    const array = []
                    const arrSuc = [];
                    if(listSuc.length === 0) {
                        data.map(ele => {
                            const item = {
                                label: ele.name,
                                value: ele.id
                            }
                            array.push(item);
                        });
                        const result = array.sort((a, b) => {
                            if ( a.label < b.label ) {
                                return -1
                            }
                            if (a.label > b.label) {
                                return 1;
                              }
                              return 0
                        })
                        setOptionsSuc(result);
                    }
                    
                }
                
            }, 
            (error) => {

            }
            )
        }
    
    const getFullData = () => {

        const data = {
            franchise_ids: '0',
            state_ids: '0',
            locality_ids: '0',
            stores_ids: '0',
          };

          const httpHandlerServices = new HttpHandlerServices();

        httpHandlerServices.postMethod(`/showstoresfull`, data)
        .then(async (res) => {
          const data = await res;
          setFullData(data);
          setFranchiseFullData(data);
        })
        .catch((err) => console.log("getMethodError: ", err.message));
      }

      const [franchiseFullData, setFranchiseFullData] = useState([])
      const [storesFullData, setStoresFullData] = useState([])
      const [statesFullData, setStatesFullData] = useState([])

    const handleChange = (event, name) => {

        if (event !== null && event?.length !== 0) {

            let franchiseArray = [];
            let statesArray = [];
            let storesArray = [];
            let franchiseFilteredArray = [];
            let statesFilteredArray = [];
            let storesFilteredArray = [];

            if (name === "franchise_ids") {

                if(hasPrevVal.state === 0 && hasPrevVal.store === 0) {
                    setHasPrevVal({...hasPrevVal, franchise: 1});
                }

                /* Obtener los datos por franquicia */

                const filteredFranchise = event.map((event) => fullData.filter((franchise) => event.label === franchise.franchise))

                filteredFranchise.forEach((franchise) => franchise.forEach((e) => franchiseFilteredArray.push(e) ));

                setFranchiseFullData(franchiseFilteredArray);

                /* Obtener los estados filtrados con base en las franquicias */
                
                const filteredStates = franchiseFilteredArray.map((franchise) => franchise["state"]).map((e, i, final) => final.indexOf(e) === i && i).filter((item) => franchiseFilteredArray[item]).map((item) => ({value: franchiseFilteredArray[item].state_id, label: franchiseFilteredArray[item].state}));
                const filteredStatesSort = filteredStates.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0
                });
                setStatesFullData(filteredStatesSort);

                /* Obtener las sucursales filtradas con base en las franquicias */

                const filteredStores = franchiseFilteredArray.map((franchise) => franchise["name"]).map((e, i, final) => final.indexOf(e) === i && i).filter((item) => franchiseFilteredArray[item]).map((item) => ({value: franchiseFilteredArray[item].id, label: franchiseFilteredArray[item].name}));
                const filteredStoresSort = filteredStores.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                      }
                      if (a.label > b.label) {
                        return 1;
                      }
                      return 0
                    });
                setStoresFullData(filteredStoresSort);

                /* Obtener el value de cada franquicia */

                event.forEach((item) => franchiseArray.push(item.value));
                filteredStatesSort.forEach((item) => statesFilteredArray.push(item.value));
                filteredStoresSort.forEach((item) => storesFilteredArray.push(item.value));

                /* Actualización del estado para mandar la informaciíon a la DB para traer los datos filtrados */
                
                setSendData({...sendData, franchise_ids: franchiseArray.join(), state_ids: statesFilteredArray.join(), stores_ids: storesFilteredArray.join() });
                setOptionsStates(filteredStatesSort);
                setOptionsSuc(filteredStoresSort);
                // getStatesList();
                // getStores()
 
            }

            if (name === "state_ids") {

                if(hasPrevVal.franchise === 0 && hasPrevVal.store === 0) {
                    setHasPrevVal({...hasPrevVal, state: 1});
                }

                /* Obtener los datos por Estado */
                const filteredStates = event.map((event) => fullData.filter((state) => event.label === state.state))

                filteredStates.forEach((state) => state.forEach((e) => statesFilteredArray.push(e) ));

                // setStatesFullData(statesFilteredArray);

                /* Obtener los estados filtrados con base en los Estados */
                
                const filteredFranchise = statesFilteredArray.map((state) => state["franchise"]).map((e, i, final) => final.indexOf(e) === i && i).filter((item) => statesFilteredArray[item]).map((item) => ({value: statesFilteredArray[item].franchise_id, label: statesFilteredArray[item].franchise }));
                const filteredFranchiseSort = filteredFranchise.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0
                });

                /* Obtener las sucursales filtradas con base en los Estados */
                let filteredStores = statesFilteredArray.map((estado) => estado["name"]).map((e, i, final) => final.indexOf(e) === i && i).filter((item) => statesFilteredArray[item]).map((item) => ({value: statesFilteredArray[item].id, label: statesFilteredArray[item].name}));

                if(hasPrevVal.franchise === 1) {
                    filteredStores = franchiseFullData.map((estado) => estado["name"]).map((e, i, final) => final.indexOf(e) === i && i).filter((item) => franchiseFullData[item]).map((item) => ({value: franchiseFullData[item].id, label: franchiseFullData[item].name}));
                }
                const filteredStoresSort = filteredStores.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                      }
                      if (a.label > b.label) {
                        return 1;
                      }
                      return 0
                    });
                setStoresFullData(filteredStoresSort);

                /* Obtener el value de cada estado */

                event.forEach((item) => statesArray.push(item.value));
                filteredFranchiseSort.forEach((item) => franchiseFilteredArray.push(item.value));
                filteredStoresSort.forEach((item) => storesFilteredArray.push(item.value));

                /* Actualización del estado para mandar la informaciíon a la DB para traer los datos filtrados */
                
                setSendData({...sendData, state_ids: statesArray.join(), stores_ids: storesFilteredArray.join() });
                setOptionsSuc(filteredStoresSort);

            }

            if (name === "stores_ids") {

                if(hasPrevVal.franchise === 0 && hasPrevVal.state === 0) {
                    setHasPrevVal({...hasPrevVal, store: 1});
                }

                /* Obtener los datos por Estado */
                const filteredStores = event.map((event) => fullData.filter((suc) => event.label === suc.name))

                filteredStores.forEach((suc) => suc.forEach((e) => storesFilteredArray.push(e) ));

                setStoresFullData(storesFilteredArray);

                /* Obtener las Franquicias filtrados con base en las Sucursales */
                
                const filteredFranchise = storesFilteredArray.map((suc) => suc["franchise"]).map((e, i, final) => final.indexOf(e) === i && i).filter((item) => storesFilteredArray[item]).map((item) => ({value: storesFilteredArray[item].franchise_id, label: storesFilteredArray[item].franchise }));
                const filteredFranchiseSort = filteredFranchise.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0
                });
                // setFranchiseFullData(filteredFranchiseSort);

                /* Obtener los Estados filtradas con base en las Sucursales */

                const filteredStates = storesFilteredArray.map((estado) => estado["state"]).map((e, i, final) => final.indexOf(e) === i && i).filter((item) => storesFilteredArray[item]).map((item) => ({value: storesFilteredArray[item].state_id, label: storesFilteredArray[item].state}));
                const filteredStatesSort = filteredStates.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                      }
                      if (a.label > b.label) {
                        return 1;
                      }
                      return 0
                    });
                setStatesFullData(filteredStatesSort);

                /* Obtener el value de cada estado */

                event.forEach((item) => storesArray.push(item.value));
                filteredFranchiseSort.forEach((item) => franchiseFilteredArray.push(item.value));
                filteredStatesSort.forEach((item) => statesFilteredArray.push(item.value));

                /* Actualización del estado para mandar la informaciíon a la DB para traer los datos filtrados */
                
                setSendData({...sendData, stores_ids: storesArray.join() });
            }
        }else{
            /* setSendData({...sendData,...sendData[name] = '0'}) */
            if(name === 'franchise_ids') {
                setSendData({...sendData, franchise_ids: '0', state_ids: '0', stores_ids: '0'});
                setHasPrevVal({...hasPrevVal, franchise: 0});
                getFullData();

                // getFranquises();

                getStatesList();
                getStores();
                setOptionSelected({
                    ...optionSelected,
                    franchiseSelected: null,
                    statesSelected: null,
                    localitySelected: null,
                    storesSelected: null
                })
                setFranchiseFullData([]);
                setStatesFullData([]);
                setStoresFullData([]);
                // resetFilters();
            }
            if(name === 'state_ids') {
                setSendData({...sendData, state_ids: '0', stores_ids: '0'});
                setHasPrevVal({...hasPrevVal, state: 0});
                setStatesFullData([]);
                setStoresFullData([]);
                setOptionSelected({
                    ...optionSelected,
                    statesSelected: null,
                    localitySelected: null,
                    storesSelected: null
                })
                getStores();
            }
            if(name === 'locality_ids') setSendData({...sendData, franchise_ids: '0', state_ids: '0', locality_ids: '0', stores_ids: '0'});
            if(name === 'stores_ids') {
                setSendData({...sendData, stores_ids: '0'});
                setHasPrevVal({...hasPrevVal, store: 0});
            }

        }
    }

    useEffect(()=>{
        getFullData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        getFullData();
        getFranquises();
        getStatesList();
        getLocalityList();
        getStores();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        sendFiltersATC();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sendData])

    return (
    <Layout>
        <div className="services">
            <Container>
                <h2 className="emigre-bold mt-5">{texts.customerSupport}</h2>
            </Container>

            <Container tag="section" className="filters mb-5">
            <Card body tag="article" className="d-block z-indez-99">
                <Row xs={12}>
                    <Col xs={12} md={3} className="mb-2">
                        <Select
                            allowSelectAll={true}
                            className="basic-single"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            components={{ Option }}
                            hideSelectedOptions={false}
                            id="franchise"
                            isMulti
                            name="franchise" 
                            noOptionsMessage={() => texts.noMoreData}
                            onChange={(e)=>{
                                setOptionSelected({
                                    ...optionSelected,
                                    franchiseSelected: e
                                })
                                handleChange(e, 'franchise_ids');
                                   
                            }}
                            options={optionsFran}
                            placeholder={texts.filterByFranchise}
                            styles={colourStyles}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ff7534',
                                  primary: '#ff7534',
                                  neutral90: 'white',
                                  primary50:"#ff7534",
                                },
                              })}
                            value={optionSelected.franchiseSelected}
                            />
                    </Col>
                    <Col xs={12} md={3} className="mb-2"> 
                    <Select
                            allowSelectAll={true}
                            className="basic-single"
                            classNamePrefix="select"
                            components={{ Option }}
                            placeholder={texts.filterByState}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            name="state" 
                            id="state"
                            isMulti
                            options={optionsStates}
                            styles={colourStyles}
                            noOptionsMessage={() => texts.noMoreData}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ff7534',
                                  primary: '#ff7534',
                                  neutral90: 'white',
                                  primary50:"#ff7534",
                                },
                              })}
                            onChange={(e)=> {
                                setOptionSelected({
                                    ...optionSelected,
                                    statesSelected: e
                                })
                                handleChange(e, 'state_ids');
                            }}
                            value={optionSelected.statesSelected}
                            />
                    </Col>
                    {/* <Col xs={12} md={3} className="mb-2">
                    <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="city" 
                            id="city"
                            placeholder={texts.filterByCity}
                            closeMenuOnSelect={true}
                            isMulti
                            noOptionsMessage={() => texts.noMoreData}
                            options={optionsCities}
                            styles={colourStyles}
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#ff7534',
                                primary: '#ff7534',
                                neutral90: 'white',
                                primary50:"#ff7534",
                              },
                            })}
                            onChange={(e)=> handleChange(e, 'locality_ids')}
                            />
                    </Col> */}
                    <Col xs={12} md={3} className="mb-2">
                        <Select
                                allowSelectAll={true}
                                className="basic-single"
                                classNamePrefix="select"
                                components={{ Option }}
                                name="sucursal" 
                                id="sucursal"
                                placeholder={texts.filterByBranch}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                noOptionsMessage={() => texts.noMoreData}
                                isMulti
                                options={optionsSuc}
                                styles={colourStyles}
                                theme={theme => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ff7534',
                                    primary: '#ff7534',
                                    neutral90: 'white',
                                    primary50:"#ff7534",
                                  },
                                })}
                                onChange={(e)=> {
                                    setOptionSelected({
                                        ...optionSelected,
                                        storesSelected: e
                                    })
                                    handleChange(e, 'stores_ids');
                                }}
                                value={optionSelected.storesSelected}
                                />
                    </Col>
                </Row>
            </Card>
        </Container>
            <TableATC dataATC={dataATC}/>
        </div>

    </Layout>)
}

export default Services; 