import React, {useEffect, useState, useContext} from 'react'
//import './TableCustomers.scss'
import { Container } from 'reactstrap';
import MaterialTable from 'material-table';
//import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { contextApp } from "../../../App/contextApp";
import language from './language.json';

const TableATC = (props) => {
    const { dataATC } = props;

    const {currentLanguage} = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])

    const getComplaintType = (type) => {
        if (!type) return  "N/A"

            if(type === "calidad de servicio") return "Calidad de Servicio"
            if(type === "calidad de producto") return "Calidad de Producto"
            if(type === "tiempo de entrega") return "Tiempo de Entrega"
            if(type === "otros") return "Otros"
            if(type === "pedido") return "Problemas al realizar mi pedido"
            if(type === "recogerpedido") return "Problema al recoger mi pedido"
            if(type === "tiempo") return "Tiempo"
            if(type==="facturacion") return "Ticket y facturación"
            if(type==="no_recibi_ticket") return "Ticket y facturación"
            if(type==="dificultad_para_facturar") return "Ticket y facturación"


            if(
                type === "temperatura" ||
                type === "producto_temperatura" ||
                type === "sabor" ||
                type === "producto_sabor" ||
                type === "presentacion" ||
                type === "producto_presentacion" ||
                type === "orden_incorrecta" ||
                type === "producto_orden_incorrecta"
            ) return "Producto"

            return type
    }

    // let categories = {tiempo_de_entrega:'tiempo_de_entrega', calidad_de_servicio:'calidad_de_servicio',others: 'others', calidad_de_producto:'calidad_de_producto'};
    let categories = {
        "tiempo de entrega":'Tiempo de Entrega',
        "calidad de servicio":'Calidad de Servicio', 
        "otros": 'Otros', 
        "calidad de producto":'Calidad de Producto',
        "pedido": "Pedido",
        "recogerpedido": "Recoger Pedido",
        "tiempo": "Tiempo",
    };

    let purchaseMode = {
        "mostrador": 'Mostrador',
        "pizzaportal": 'Pizza Portal',
        "delivery": 'Delivery',
    };
    
    const status = {'Sin contestar':'Sin contestar', 'Contestada':'Contestada', 'Sin Contestar Abierta':'Sin Contestar Abierta', 'Contestando': 'Contestando'};

    const columns = [
            {field: "name", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-user_service mr-1" /> {texts.client}</div>, 
            render: rowData => {
                // return rowData.messages_unsee > 0 ?
                return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                    <div style={{fontWeight: "bold", color:"#ff7534" }}>
                        {rowData.name}
                    </div> 
                        : 
                    <span>
                        {rowData.name}
                    </span>

            }
            }, 
            {field: "complaint_channel", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-tag mr-1" /> {texts.purchaseMode} </div>, lookup: purchaseMode,
                render: rowData => {
                    // return rowData.messages_unsee > 0 ?
                    return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                        <div style={{fontWeight: "bold", color:"#ff7534" }}>
                            {   !rowData.complaint_channel ? "N/A" :
                                rowData.complaint_channel === "pizzaportal" ? "Pizza Portal" : rowData.complaint_channel &&
                                rowData.complaint_channel === "delivery" ? "Delivery" : rowData.complaint_channel &&
                                rowData.complaint_channel === "mostrador" ? "Mostrador" : rowData.complaint_channel
                            }
                        </div> 
                            : 
                        <span>
                            {   !rowData.complaint_channel ? "N/A" :
                                rowData.complaint_channel === "pizzaportal" ? "Pizza Portal" : rowData.complaint_channel &&
                                rowData.complaint_channel === "delivery" ? "Delivery" : rowData.complaint_channel &&
                                rowData.complaint_channel === "mostrador" ? "Mostrador" : rowData.complaint_channel
                            }
                        </span>
                }
            }, 
            // {field: "complaint_type", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-tag mr-1" /> {texts.category} </div>, lookup: categories,
            {field: "complaint_type", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-tag mr-1" /> {texts.category} </div>, lookup: categories,
                render: rowData => {
                    // return rowData.messages_unsee > 0 ?
                    return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                        <div style={{fontWeight: "bold", color:"#ff7534" }}>
                            {/* {rowData.complaint_type} */}
                            {getComplaintType(rowData.complaint_type)}
                        </div> 
                            : 
                        <span>
                            {/* {rowData.complaint_type} */}
                            {getComplaintType(rowData.complaint_type)}
                        </span>

                }
            }, 
            {field: "created_at", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-date mr-1" /> {texts.date}</div>,
            render: rowData => {
                // return rowData.messages_unsee > 0 ?
                return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                    <div style={{fontWeight: "bold", color:"#ff7534" }}>
                        {rowData.created_at}
                    </div> 
                        : 
                    <span>
                        {rowData.created_at}
                    </span>

            }
            }, 
            {field: "franchise", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-shop mr-1" /> {texts.franchise}</div>,
                render: rowData => {
                // return rowData.messages_unsee > 0 ?
                return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                    <div style={{fontWeight: "bold", color:"#ff7534" }}>
                        {rowData.franchise}
                    </div> 
                        : 
                    <span>
                        {rowData.franchise}
                    </span>

            }
            },
            {field: "store", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-sucursal mr-1" /> {texts.store}</div>,
            render: rowData => {
                // return rowData.messages_unsee > 0 ?
                return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                    <div style={{fontWeight: "bold", color:"#ff7534" }}>
                        {rowData.store}
                    </div> 
                        : 
                    <span>
                        {rowData.store}
                    </span>

            }
            }, 
            {field: "hasmessage", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-conversando mr-1" /> {texts.typeOfComplaint}</div>,
            render: rowData => {
                // return rowData.messages_unsee > 0 ?
                return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                    <div style={{fontWeight: "bold", color:"#ff7534" }}>
                        {rowData.hasmessage}
                    </div> 
                        : 
                    <span>
                        {rowData.hasmessage}
                    </span>

            }
            },
            {field: "status", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-edit mr-1" /> {texts.status}</div>,
            render: rowData => {
                // return rowData.messages_unsee > 0 ?
                return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                    <div style={{fontWeight: "bold", color:"#ff7534" }}>
                        {rowData.status}
                    </div> 
                        : 
                    <span>
                        {rowData.status}
                    </span>

            }
            },
            {field: "assigned_to", title: <div className='text-uppercase d-flex align-items-center'><i className="icomoon icon-user_service mr-1" /> {texts.servedBy}</div>,
            render: rowData => {
                // return rowData.messages_unsee > 0 ?
                return (rowData.status === 'Nuevas' || rowData.status === "Contestando") ?
                    <div style={{fontWeight: "bold", color:"#ff7534" }}>
                        {rowData.assigned_to}
                    </div> 
                        : 
                    <span>
                        {rowData.assigned_to}
                    </span>

            }
            },
        ];
        
    const openChat = (selectedRow) => {
        /* const url = `/atencion/chat/showconversationsinit/${selectedRow.id}`;
        var win = window.open(url, '_blank');
        
        win.focus(); */
       
        props.history.push(`/atencion/chat/showconversationsinit/${selectedRow.id}/${selectedRow.ktbots_id}`);
    }

    if (dataATC.length > 0) {
        dataATC.map(item => {
            if(item.name.indexOf('handler') !== -1){
                item.name = "Not Available";
            }
        })

    } else {
        /* toast.warning(`SE PRODUJO UN ERROR POR FAVOR CONTACTE A SOPORTE ERROR 500`, {

            position: toast.POSITION.BOTTOM_RIGHT,

            autoClose: 8000

        });
 */

    }

    return(
        <>
        {console.log(dataATC)}
            <Container className="customers">

                <MaterialTable 
                title=""
                columns={columns} 
                data={dataATC} 
                options={{
                    filtering: true, headerStyle: {
                    backgroundColor: '#424242',
                    color: '#FFF',
                    textAlign: 'center',
                    fontWeight: 'Bold'
                  }
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: texts.noMoreData,
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    },
                    toolbar: {
                      searchTooltip: texts.search,
                      searchPlaceholder: texts.search
                    },
                    pagination: {
                      labelRowsSelect: texts.labelRowsSelect,
                      labelDisplayedRows: ' {from}-{to} de {count}',
                      firstTooltip: texts.firstTooltip,
                      previousTooltip: texts.previousTooltip,
                      nextTooltip: texts.nextTooltip,
                      lastTooltip: texts.lastTooltip
                    }
                }}
                onRowClick = {((evt, selectedRow) => openChat(selectedRow))}
                
                />
            </Container>
        </>
    );
}

export default withRouter(TableATC)