import React, {useContext, useState, useEffect} from "react";
import './PurchaseChannel.scss';
import { Container, Row, Col, Card, Badge } from 'reactstrap';
import { contextApp } from '../../../App/contextApp';
import language from './language.json';
import Loading from "../../../loading/Loading";


const PurchaseChannel = ({ dataGeneral, dataIncidences }) =>{
    const { pizzaportal, mostrador, delivery } = dataIncidences;

    const { 
          } = dataGeneral;

    const {       
        currentLanguage,
        loading
    } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])

    let sumDataPizzaPortal;
    let dataPizzaPortalPercent;
    let sumDataDelivery;
    let dataDeliveryPercent;
    let sumMostrador;
    let MostradorPercent;

    const getTotalIncidentsPercent = (sumTotal) => {
        if (sumTotal === 0) return
        dataPizzaPortalPercent = parseFloat((sumDataPizzaPortal * 100) / sumTotal).toFixed(1);
        dataDeliveryPercent = parseFloat((sumDataDelivery * 100) / sumTotal).toFixed(1);
        MostradorPercent = parseFloat((sumMostrador * 100) / sumTotal).toFixed(1);
    }

    const getTotalSumIncidents = (sumDataPizzaPortal, sumDataDelivery, sumMostrador) => {
         const sumTotal = sumDataPizzaPortal + sumDataDelivery + sumMostrador
         getTotalIncidentsPercent(sumTotal)
    }

    const getTotalIncidents = (dataIn) => {
        return Object.entries(dataIn)
                .map(([_,e]) => e)
                .map((f) => f.reduce((acc,cur) => (acc+cur), 0))
                .reduce((acc,cur) => acc+cur, 0);
    }

    if(pizzaportal && delivery && mostrador) {
        sumDataPizzaPortal = getTotalIncidents(pizzaportal)
        sumDataDelivery = getTotalIncidents(delivery)
        sumMostrador = getTotalIncidents(mostrador)
        getTotalSumIncidents(sumDataPizzaPortal, sumDataDelivery, sumMostrador)
    }

    return (<Container tag="section">
            <Row className="mb-5">
                <Col xs="12">
                    <h4 className='emigre-bold text-secondary'>{texts.totalPurchaseChannel}</h4>
                    {(loading || Object.entries(dataIncidences).length <= 0) ? (
                        <Loading />
                        ) : null}
                    <Card body tag="article">
                        <Row>
                            <Col xs={12} md={4} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"> {texts.pizzaportal}</h6>
                                <Row className="justify-content-center my-4 align">
                                    <Col xs={5} className="d-flex align-items-center justify-content-end">
                                       {/* <p className="whiteCapsule m-0">{franchise_total ? franchise_total : 0}</p> */}
                                       <p className="m-0 py-2 px-4">{sumDataPizzaPortal ? sumDataPizzaPortal : 0}</p>
                                    </Col>
                                    <Col xs={2}>
                                        <hr />
                                    </Col>
                                    <Col xs={5} className="d-flex align-items-center justify-content-start">
                                    {/* <p className="emigre-bold text-dark text-uppercase m-0">{franchise ? franchise : 'Franquicia'}</p> */}
                                    <p className="m-0 badge badge-primary text-white py-2 px-4">{dataPizzaPortalPercent ? dataPizzaPortalPercent : 0}%</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"> {texts.delivery}</h6>
                                <Row className="justify-content-center my-4 align">
                                    <Col xs={5} className="d-flex align-items-center justify-content-end">
                                       {/* <p className="whiteCapsule m-0">{franchise_total ? franchise_total : 0}</p> */}
                                       <p className="m-0 py-2 px-4">{sumDataDelivery ? sumDataDelivery : 0}</p>
                                    </Col>
                                    <Col xs={2}>
                                        <hr />
                                    </Col>
                                    <Col xs={5} className="d-flex align-items-center justify-content-start">
                                    {/* <p className="emigre-bold text-dark text-uppercase m-0">{franchise ? franchise : 'Franquicia'}</p> */}
                                    <p className="m-0 badge badge-primary text-white py-2 px-4">{dataDeliveryPercent ? dataDeliveryPercent : 0}%</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"> {texts.mostrador}</h6>
                                <Row className="justify-content-center my-4 align">
                                    <Col xs={5} className="d-flex align-items-center justify-content-end">
                                       {/* <p className="whiteCapsule m-0">{franchise_total ? franchise_total : 0}</p> */}
                                       <p className="m-0 py-2 px-4">{sumMostrador ? sumMostrador : 0}</p>
                                    </Col>
                                    <Col xs={2}>
                                        <hr />
                                    </Col>
                                    <Col xs={5} className="d-flex align-items-center justify-content-start">
                                    {/* <p className="emigre-bold text-dark text-uppercase m-0">{franchise ? franchise : 'Franquicia'}</p> */}
                                    <p className="m-0 badge badge-primary text-white py-2 px-4">{MostradorPercent ? MostradorPercent : 0}%</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    
                </Col>
            </Row> 
    
    </Container>)
    }


export default PurchaseChannel