import React, { useState, useEffect, useContext } from "react"
import {  Row, Col, Card, Badge, CardBody, CardTitle, Container } from 'reactstrap'
import { contextApp } from "../../../../App/contextApp"


import language from '../language.json'

export default function TotalComplaints({ dataGeneral }) {

    const { 
        franchise_total,
        franchise_list,
        subsidiary_list
      } = dataGeneral;

    const { currentLanguage } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

      

    },[currentLanguage])

    const getComplaintsWithCommentsCRM = () => {
        const totalByFranchise = franchise_list?.map(e => e.comentario_cerradas_crm)?.reduce((acc, cur) => acc+cur, 0);
        const totalBySubsidiary = subsidiary_list?.map(e => e.comentario_cerradas_crm)?.reduce((acc, cur) => acc+cur, 0);

        // return totalByFranchise + totalBySubsidiary;
        return totalByFranchise;
    }

    const getComplaintsWithCommentsFB = () => {
        const totalByFranchiseFBCRM = franchise_list?.map(e => e.comentario_cerradas_fb_crm)?.reduce((acc, cur) => acc+cur, 0);
        const totalByFranchiseFBUsers = franchise_list?.map(e => e.comentario_cerradas_fb_usuario)?.reduce((acc, cur) => acc+cur, 0);
        const totalBySubsidiaryFBCRM = subsidiary_list?.map(e => e.comentario_cerradas_fb_crm)?.reduce((acc, cur) => acc+cur, 0);
        const totalBySubsidiaryFBUsers = subsidiary_list?.map(e => e.comentario_cerradas_fb_usuario)?.reduce((acc, cur) => acc+cur, 0);

        // return totalByFranchiseFBCRM + totalByFranchiseFBUsers + totalBySubsidiaryFBCRM + totalBySubsidiaryFBUsers;
        return totalByFranchiseFBCRM + totalByFranchiseFBUsers;
    }

    // const getComplaintsWithoutCommentsCRM = () => {
    //     const totalByFranchise = franchise_list?.map(e => e.comentario_cerradas_crm)?.reduce((acc, cur) => acc+cur, 0);
    //     const totalBySubsidiary = subsidiary_list?.map(e => e.comentario_cerradas_crm)?.reduce((acc, cur) => acc+cur, 0);

    //     return totalByFranchise + totalBySubsidiary;
    // }

    const getComplaintsWithoutCommentsFB = () => {
        const totalByFranchiseFB = franchise_list?.map(e => e.sincomentario_cerrada_fb)?.reduce((acc, cur) => acc+cur, 0);
        const totalBySubsidiaryFB = subsidiary_list?.map(e => e.sincomentario_cerrada_fb)?.reduce((acc, cur) => acc+cur, 0);

        // return totalByFranchiseFB + totalBySubsidiaryFB;
        return totalByFranchiseFB;
    }

    return (
        <Container>
        <Row className="mb-4">
            <Col xs={12}>
                <Card body tag="article">
                        <Row>
                            <Col xs={12} md={6} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-shop"></i> {texts.totalcomplaintsWithComments}</h6>
                                <Row className="justify-content-center my-4 align">
                                    <Col xs={3} className="d-flex align-items-center">
                                       <p className="whiteCapsule m-0">{franchise_list && subsidiary_list ? getComplaintsWithCommentsCRM() : 0}</p>
                                    </Col>
                                    <Col xs={3} className="d-flex align-items-center justify-content-center">
                                    <p className="emigre-bold text-dark text-uppercase m-0">{texts.closeByCRM}</p>
                                    </Col>
                                    <Col xs={3} className="d-flex align-items-center">
                                       <p className="whiteCapsule m-0">{franchise_list && subsidiary_list ? getComplaintsWithCommentsFB() : 0}</p>
                                    </Col>
                                    <Col xs={3} className="d-flex align-items-center justify-content-center">
                                    <p className="emigre-bold text-dark text-uppercase m-0">{texts.closeByFacebook}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-shop"></i> {texts.totalcomplaintsWithoutComments}</h6>
                                <Row className="justify-content-center my-4 align">
                                    {/* <Col xs={3} className="d-flex align-items-center">
                                       <p className="whiteCapsule m-0">{franchise_total ? franchise_total : 0}</p>
                                    </Col>
                                    <Col xs={3} className="d-flex align-items-center justify-content-center">
                                    <p className="emigre-bold text-dark text-uppercase m-0">{texts.closeByCRM}</p>
                                    </Col> */}
                                    <Col xs={6} className="d-flex align-items-center">
                                       <p className="whiteCapsule m-0">{franchise_list && subsidiary_list ? getComplaintsWithoutCommentsFB() : 0}</p>
                                    </Col>
                                    <Col xs={6} className="d-flex align-items-center justify-content-center">
                                    <p className="emigre-bold text-dark text-uppercase m-0">{texts.closeByFacebook}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
            </Col> 
        </Row> 
        </Container>
    )
}
