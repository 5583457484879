import React, {useContext, useState, useEffect} from "react";
import { contextApp } from "../../../App/contextApp";
import { Container, Row, Col } from 'reactstrap';
import Servicios from "./Servicios/Servicios";
import './ComplaintDetailDelivery.scss'
import Products from "./Products/Products";
import Times from "./Time/Time";
import language from './languaje.json';
import ProblemOrdering from "./ProblemOrdering/ProblemOrdering";
import ProblemPickingUpOrder from "./ProblemPickingUpOrder/ProblemPickingUpOrder";
import CardsURD from "../PercentCategoriesDelivery/CardsURD/CardsURD";
import Other from "./Other/Other";
import Ticket from "./Ticket/Ticket";

const ComplaintDetailDelivery = (props) => {

    const { dataIncidences } = props;
    const { selectValue, currentLanguage } = useContext(contextApp);
    const { monthsLabel } = selectValue;
    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

      

    },[currentLanguage])
    return(
        <Container>
            <Row className="mb-5">
                <Col xs="12">
                    <h4 className='emigre-bold text-secondary text-left text-uppercase'>
                        {texts.complaintsDetailbyCat}
                    </h4>
                </Col>
                <Col xs="12">
                    <Products dataIncidences={dataIncidences} monthsLabel={monthsLabel} texts={texts}/>
                </Col>
                <Col xs="12">
                    <Times dataIncidences={dataIncidences} monthsLabel={monthsLabel} texts={texts}/>
                </Col>
                <Col xs="12">
                    <Ticket dataIncidences={dataIncidences} monthsLabel={monthsLabel} texts={texts}/>
                </Col>
                <Col xs="12">
                    <Other dataIncidences={dataIncidences} monthsLabel={monthsLabel} texts={texts}/>
                </Col>
            </Row>  
           
        </Container>       
    );

}

export default ComplaintDetailDelivery;