import React, { useContext, useEffect } from "react";
import ComplaintDetail from "../../ComplaintDetails/ComplaintDetail";
import { Container, Row, Col, Card } from "reactstrap";

import PercentCategories from "../../PercentCategories/PercentCategories";
import ComplaintDetailPizzaPortal from "../../ComplaintDetailsPizzaPortal/ComplaintDetailPizzaPortal";
import PercentCategoriesDelivery from "../../PercentCategoriesDelivery/PercentCategoriesDelivery";
import PercentCategoriesMostrador from "../../PercentCategoriesMostrador/PercentCategoriesMostrador";
import ComplaintDetailDelivery from "../../ComplaintDetailsDelivery/ComplaintDetailDelivery";
import ComplaintDetailMostrador from "../../ComplaintDetailsMostrador/ComplaintDetailMostrador";
import HistoricalTotal from "../../HistoricalTotal/HistoricalTotal";
import { contextApp } from "../../../../App/contextApp";
import Loader from "../../../../loader/Loader";
import { useState } from "react";

const PurchaseChannelStatistics = ({
  dataCategoriesResume,
  dataCategories,
  dataIncidences,
  isLoaded
}) => {

  const {refPurchase} = useContext(contextApp)
  return (
    <>
      {!dataIncidences || dataIncidences.length  <= 0 || !isLoaded  ? <Loader /> : null}
      <div ref={refPurchase}>
        <PercentCategoriesMostrador 
          dataCategoriesResume={dataCategoriesResume}
          dataCategories={dataCategories}
          dataIncidences={dataIncidences}
        />
        <ComplaintDetailMostrador dataIncidences={dataIncidences}/>

        <PercentCategories
          dataCategoriesResume={dataCategoriesResume}
          dataCategories={dataCategories}
          dataIncidences={dataIncidences}
        />
        <ComplaintDetailPizzaPortal dataIncidences={dataIncidences} />

        <PercentCategoriesDelivery
          dataCategoriesResume={dataCategoriesResume}
          dataCategories={dataCategories}
          dataIncidences={dataIncidences}
        />
        <ComplaintDetailDelivery dataIncidences={dataIncidences}/>
      </div>
    </>
  );
};

export default PurchaseChannelStatistics;
