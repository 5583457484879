import React,{useState, useEffect, useContext} from "react"
import { Container, Row, Col, Card } from 'reactstrap'
import PieChart from './PieChart/PieChart'
import DoughnutCharts from "./DougnutCharts/DougnutCharts";
import { contextApp } from '../../../App/contextApp';
import language from './language.json'
import Loading from "../../../loading/Loading";

const PercentCategories = (props) => {

    const { dataCategoriesResume, dataCategories, dataIncidences } = props;

    const { product, service, time, other } = dataCategories;

    const { pizzaportal } = dataIncidences;

    const {       
        currentLanguage,
        loading
    } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }


    },[currentLanguage])

    return (
        <Container tag="section">
            <Row className="mb-4">
                <Col xs={12}>
                    <h4 className="emigre-bold text-uppercase">{texts.titlePizzaPortal}</h4>
                </Col>
                <Col xs={12}>
                    <h4 className="emigre-bold text-secondary text-uppercase">{texts.totalIncidentsByCat}</h4>
                </Col>
                <Col xs={12}>
                {loading ? (
                    <Loading />
                    ) : null}
                    <Card className="p-4">
                        <Row>
                            <Col xs={12} lg={6} >
                                <PieChart dataCategoriesResume={dataCategoriesResume} texts={texts} dataIncidences={dataIncidences}/>
                            </Col>
                            <Col xs={12} lg={6}>
                                <DoughnutCharts product={product} service={service} time={time} other={other} texts={texts} pizzaPortal={pizzaportal}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PercentCategories;