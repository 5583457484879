import React, { useState, useEffect, useContext } from "react";
import { contextApp } from "../App/contextApp";
import Select from "react-select";
import { components } from "react-select";
import HttpHandlerServices from "../../services/httpService";
import { Container, Row, Col, Card } from "reactstrap";
import "./Filters.scss";
import language from "./language.json";
import DatePicker from "../DatePicker/DatePicker";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const Filters = (props) => {
  const {
    type,
    filterHandler,
    dataFilters,
    setDataFilter,
    sendFilters,
    monthRangeHandler,
    onRefetch,
    exportFile
  } = props;
  const {
    selectValue,
    sendData,
    setSendData,
    sendFiltersATC,
    currentLanguage,
  } = useContext(contextApp);

  const [showFilters, setShowFilters] = useState(true);

  const [fullData, setFullData] = useState([]);

  const [optionsFran, setOptionsFran] = useState([]);

  const [optionsStates, setOptionsStates] = useState([]);

  const [optionsSuc, setOptionsSuc] = useState([]);

  const [disable, setDisable] = useState(false)

  const [optionSelected, setOptionSelected] = useState({
    franchiseSelected: null,
    statesSelected: null,
    localitySelected: null,
    storesSelected: null,
  });

  const [texts, setTexts] = useState(language.english);

  useEffect(() => {
    if (currentLanguage === "spanish") {
      setTexts(language.spanish);
    }
  }, [currentLanguage]);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? "#ff7534"
          : null,
        color: isDisabled ? "white" : isSelected,
        cursor: isDisabled ? "not-allowed" : "white",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "white" : "#ff7534"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#ff7534",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: data.color,
        color: "#dc3545",
      },
    }),
  };

  const methodsFiltersNew = {
    getFullData: (d) => {
      setDisable(true)
      const httpHandlerServices = new HttpHandlerServices();
      let data
      if(d){
        data = {
          franchise_ids: d.franchise,
          state_ids: d.state,
          locality_ids: d.city,
          stores_ids: d.sucursal,
        };
      }else{
         data = {
          franchise_ids: sendData.franchise,
          state_ids: sendData.state,
          locality_ids: sendData.city,
          stores_ids: sendData.sucursal,
        };
      }

      httpHandlerServices
        .postMethod(`/showstoresfull`, data)
        .then(async (res) => {
          const data = await res;
          setFullData(data);
          setDisable(false)
        })
        .catch((err) => console.log("getMethodError: ", err.message));
    },

    getFranchises: () => {
      const endpoit = "/showfranchise";
      const httpHandlerServices = new HttpHandlerServices();
      httpHandlerServices.getMethod(endpoit).then(
        async (res) => {
          const data = await res;
          const array = [];
          data.map((item) => {
            const obj = {
              value: item.id,
              label: item.franchise,
            };
            array.push(obj);
          });
          setOptionsFran(array);
        },
        async (error) => {
          const err = await error;
        }
      );
    },

    getStatesList: () => {
      const httpHandlerServices = new HttpHandlerServices();
      const url = `/showstate/0`;
      httpHandlerServices.getMethod(url).then(
        async (res) => {
          const data = await res;

          if (data) {
            const array = [];
            data.map((ele) => {
              const item = {
                label: ele.state,
                value: ele.id,
              };
              array.push(item);
            });
            setOptionsStates(array);
            // setOptionsStates()
          }
        },
        (error) => {}
      );
    },

    getStoresList: (listSuc = []) => {
      const httpHandlerServices = new HttpHandlerServices();
      const url = `/showstores`;

      httpHandlerServices.getMethod(url).then(
        async (res) => {
          const data = await res;
          if (data) {
            const array = [];
            const arrSuc = [];
            if (listSuc.length === 0) {
              data.map((ele) => {
                const item = {
                  label: ele.name,
                  value: ele.id,
                };
                array.push(item);
              });
              const result = array.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              });
              setOptionsSuc(result);
            }
          }
        },
        (error) => {}
      );
    },

    handleChange: async (event, name) => {
      if (event !== null && event?.length !== 0) {
        let franchiseArray = [];
        let statesArray = [];
        let storesArray = [];
        let franchiseFilteredArray = [];
        let statesFilteredArray = [];
        let storesFilteredArray = [];
        let data=fullData
        console.log(data,'12345');

        if (name === "franchise") {
          const filteredFranchise = event.map((event) =>
            data.filter((franchise) => event.label === franchise.franchise)
          );

          filteredFranchise.forEach((franchise) =>
            franchise.forEach((e) => franchiseFilteredArray.push(e))
          );

          const filteredStates = franchiseFilteredArray
            .map((franchise) => franchise["state"])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((item) => franchiseFilteredArray[item])
            .map((item) => ({
              value: franchiseFilteredArray[item].state_id,
              label: franchiseFilteredArray[item].state,
            }));
          const filteredStatesSort = filteredStates.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          const filteredStores = franchiseFilteredArray
            .map((franchise) => franchise["name"])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((item) => franchiseFilteredArray[item])
            .map((item) => ({
              value: franchiseFilteredArray[item].id,
              label: franchiseFilteredArray[item].name,
            }));
          const filteredStoresSort = filteredStores.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          event.forEach((item) => franchiseArray.push(item.value));
          setSendData({ ...sendData, franchise: franchiseArray.join() });
          // filterHandler(franchiseArray.join());
          setDataFilter({
            ...dataFilters,
            franchise: franchiseArray.join(),
            state: "0",
            sucursal: "0",
          });
          setOptionsStates(filteredStatesSort);
          setOptionsSuc(filteredStoresSort);
        }

        if (name === "state") {
          const filteredStates = event.map((event) =>
            data.filter((state) => event.label === state.state)
          );

          filteredStates.forEach((state) =>
            state.forEach((e) => statesFilteredArray.push(e))
          );
          // AOL: Fix Sucursales
          /*const filteredStores = statesFilteredArray
            .map((estado) => estado["name"])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((item) => statesFilteredArray[item])
            .map((item) => ({
              value: statesFilteredArray[item].id,
              label: statesFilteredArray[item].name,
            }));*/
            const filteredStores = optionsSuc;
            //console.log("optionsSuc")
            //console.log(optionsSuc)
          const filteredStoresSort = filteredStores.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          event.forEach((item) => statesArray.push(item.value));
          setSendData({ ...sendData, state: statesArray.join() });
          // filterHandler(name, statesArray.join());
          setDataFilter({
            ...dataFilters,
            state: statesArray.join(),
            sucursal: "0",
          });
          setOptionsSuc(filteredStoresSort);
        }

        if (name === "sucursal") {
          const filteredStores = event.map((event) =>
            data.filter((suc) => event.label === suc.name)
          );

          filteredStores.forEach((suc) =>
            suc.forEach((e) => storesFilteredArray.push(e))
          );

          event.forEach((item) => storesArray.push(item.value));

          setSendData({ ...sendData, sucursal: storesArray.join() });
          // filterHandler(name, storesArray.join() )
          setDataFilter({
            ...dataFilters,
            sucursal: storesArray.join(),
          });
        }
      } else {
        if (name === "franchise") {
          let d = {
            franchise: "0",
            state: "0",
            sucursal: "0",
            city:"0"
          }
          setSendData(d);
          methodsFiltersNew.getFullData(d);
          methodsFiltersNew.getFranchises();
          methodsFiltersNew.getStatesList();
          methodsFiltersNew.getStoresList();

          setOptionSelected({
            ...optionSelected,
            franchiseSelected: null,
            statesSelected: null,
            localitySelected: null,
            storesSelected: null,
          });
          // filterHandler(name, '0');
          setDataFilter({
            ...dataFilters,
            franchise: "0",
            state: "0",
            sucursal: "0",
          });
        }

        if (name === "state") {
          setSendData({ ...sendData, state: "0", sucursal: "0" });
          // filterHandler(name, '0');
          setDataFilter({
            ...dataFilters,
            state: "0",
            sucursal: "0",
          });
          setOptionSelected({
            ...optionSelected,
            statesSelected: null,
            localitySelected: null,
            storesSelected: null,
          });
          methodsFiltersNew.getStoresList();
        }

        if (name === "sucursal") {
          setSendData({ ...sendData, sucursal: "0" });
          // filterHandler(name, '0');
          setDataFilter({
            ...dataFilters,
            sucursal: "0",
          });
        }
      }

      methodsFiltersNew.sendFilters();
    },
    sendFilters: () => {
      if (type === "dashboard") {
        //sendFilters(selectValue.months, selectValue.monthsRange);
      } else if (type === "atc-table") {
        // sendFiltersATC();
      }
    },
  };

  const sleep = function(ms){
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  useEffect(() => {
    methodsFiltersNew.getFullData();
    methodsFiltersNew.getFranchises();
    methodsFiltersNew.getStatesList();
    methodsFiltersNew.getStoresList();
    if (type === "dashboard") {
      //methodsFilters.sendFilters();
    } else if (type === "atc-table") {
      methodsFiltersNew.sendFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Container tag="section" className="filters mb-5">
      <Card body tag="article" className="d-block z-indez-99">
        <Row xs={12} className="mb-2 ">
          <Col xs={12} md={4} className="pr-0 pl-0">
          <p className="peri">Periodo</p>
            <DatePicker
              sendFilters={sendFilters}
              monthRangeHandler={monthRangeHandler}
              exportFile={exportFile}
            />
          </Col>
          <Col xs={12} md={2} className="mb-2 mr-8">
          <p>Franquicia</p>
            <Select
              allowSelectAll={true}
              className="basic-single"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              components={{ Option }}
              hideSelectedOptions={false}
              id="franchise"
              isMulti
              name="franchise"
              isDisabled={disable}
              noOptionsMessage={() => texts.noMoreData}
              options={optionsFran}
              placeholder={texts.filterByFranchise}
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ff7534",
                  primary: "#ff7534",
                  neutral90: "white",
                  primary50: "#ff7534",
                },
              })}
              onChange={(e) => {
                setOptionSelected({
                  ...optionSelected,
                  franchiseSelected: e,
                });
                methodsFiltersNew.handleChange(e, "franchise");
              }}
              value={optionSelected.franchiseSelected}
            />
          </Col>

          {/* <Col xs={12} md={3} className="mb-2">
                    <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={texts.filterByState}
                            closeMenuOnSelect={true}
                            name="state" 
                            id="state"
                            isMulti
                            options={optionsStates}
                            styles={colourStyles}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ff7534',
                                  primary: '#ff7534',
                                  neutral90: 'white',
                                  primary50:"#ff7534",
                                },
                              })}
                            onChange={(e)=> methodsFilters.handleChange(e, 'state')}
                            />
                    </Col> */}

          <Col xs={12} md={2} className="mb-2 mr-8">
          <p>Estado</p>
            <Select
              allowSelectAll={true}
              className="basic-single"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              components={{ Option }}
              hideSelectedOptions={false}
              id="state"
              isMulti
              isDisabled={disable}
              name="state"
              options={optionsStates}
              placeholder={texts.filterByState}
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ff7534",
                  primary: "#ff7534",
                  neutral90: "white",
                  primary50: "#ff7534",
                },
              })}
              onChange={(e) => {
                setOptionSelected({
                  ...optionSelected,
                  statesSelected: e,
                });
                methodsFiltersNew.handleChange(e, "state");
              }}
              value={optionSelected.statesSelected}
            />
          </Col>

          {/* <Col xs={12} md={3} className="mb-2">
                    <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="city" 
                            id="city"
                            // placeholder={texts.filterByFranchise}
                            placeholder='Jejeje'
                            closeMenuOnSelect={true}
                            isMulti
                            noOptionsMessage={() => texts.noMoreData}
                            options={optionsCities}
                            styles={colourStyles}
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#ff7534',
                                primary: '#ff7534',
                                neutral90: 'white',
                                primary50:"#ff7534",
                              },
                            })}
                            onChange={(e)=> methodsFilters.handleChange(e, 'city')}
                            />
                    </Col> */}
          <Col xs={12} md={2} className="mb-2 mr-8">
          <p>Sucursal</p>
            <Select
              allowSelectAll={true}
              className="basic-single"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              components={{ Option }}
              hideSelectedOptions={false}
              id="sucursal"
              isMulti
              isDisabled={disable}
              name="sucursal"
              options={optionsSuc}
              placeholder={texts.filterByBranch}
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ff7534",
                  primary: "#ff7534",
                  neutral90: "white",
                  primary50: "#ff7534",
                 
                },
              })}
              onChange={(e) => {
                setOptionSelected({
                  ...optionSelected,
                  storesSelected: e,
                });
                methodsFiltersNew.handleChange(e, "sucursal");
              }}
              value={optionSelected.storesSelected}
            />
          </Col>
        </Row>
        {selectValue.franchise !== "" && (
          <Row>
            <Col xs={12}>
              <h6
                className="text-secondary mt-2 mb-3emigre-bold text-uppercase show-filters"
                align="center"
                onClick={() => setShowFilters(!showFilters)}
              >
                {showFilters ? "Ocultar filtros" : "mostrar Filtros"}
              </h6>
            </Col>
          </Row>
        )}
        <Row>
          <div className="container-button">
            <button className="button-filter" onClick={()=>onRefetch()}>APLICAR FILTROS</button>
          </div>
        </Row>
      </Card>
    </Container>
  );
};

export default Filters;
