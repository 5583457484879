import React, { useEffect, useState } from "react";
import { Row, Col, Table, Badge } from "reactstrap";

const ProblemOrderingTable = ({ dataProblemOrderingSort }) => {

  return (
      <Table className="dougnut-charts-table__table">
        <tbody>
           {dataProblemOrderingSort.map(({ name, value, id }) => (
            <tr key={id}>
              <td className="dougnut-charts-table__td-no-borders text-right border-right">
                <tr>
                  <td className="dougnut-charts-table__td-title">
                    {name}
                  </td>
                  <td
                    className="dougnut-charts-table__td-value align-middle"
                    style={{ width: "10%" }}
                  >
                    <div
                    className={`badge-box__problem-ordering-${id}`}
                    />
                  </td>
                </tr>
              </td>
              <td className="align-middle">{value}</td>
            </tr>
          ))} 
        </tbody>
      </Table>
  );
};

export default ProblemOrderingTable;
