import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, Col } from "reactstrap";
import {SelectChangeCategorie,SelectChangeChannel,SelectChangeReason} from '../selectChange'
import HttpHandlerServices from '../../../../services/httpService';
import { toast } from "react-toastify";
import './ModalChange.scss'

export function ModalChange(props) {
    
    const {detail} = props
    console.log(detail);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [channel, setChannel] = useState(null)
    const [categorie, setCategorie] = useState(null)
    const [reason, setReason] = useState(null)
    const [disabled, setDisabled] = useState(true)
    
    const toggle = () => {
        setModal(!modal);
      };

      const toggle2 = () => {
        setModal2(!modal2);
      };

      const showModal = () =>{
        setModal(true)
      }

      const showModal2 = () =>{
        setModal2(true)
      }

      const sendData=async()=>{

        setDisabled(true)
       
        let data={}

        if(channel.value === "mostrador" || channel.value === "pizzaportal"){
          
            data ={
              ktbots_id:detail.ktbots_id,
              complaint_channel:channel.value,
              complaint_type:categorie.value,
              complaint_reason:reason.value
            }
          
        }else{
          let newChannelCate = channel.value.split("-")
          
            data={
              ktbots_id:detail.ktbots_id,
              complaint_channel:newChannelCate[0],
              complaint_type:newChannelCate[1],
              complaint_reason:reason.value
            }
          
        }
        await postChange(data)
        
      
      }

      const postChange = async (data) =>{
        const endpoint='/changecomplaint'
        
        const httpHandlerServices = new HttpHandlerServices();
        httpHandlerServices.postMethod(endpoint,data).then(
          async (res) => {
            const data = await res;
            toast.error(
              `La reclasificación se guardó exitosamente`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
    
                autoClose: 3000,
              }
            );
            setTimeout(() => {
              window.location.reload()
            }, "4500");
          },
          (error) => {
            toast.error(
              `ERROR AL SUBIR DATOS, Cierre la session y vuelva a loguearse. Si el error persiste por favor por farvor contacte a soporte help@littlecaesars.com`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
    
                autoClose: 5000,
              }
            );
            setDisabled(false)
          }
        )
      }
      

    useEffect(() => {
        setCategorie(null)
        setReason(null)
    }, [channel])

    useEffect(() => {
        setReason(null)
    }, [categorie])

    useEffect(() => {
      if(channel && categorie && reason){
        setDisabled(false)
      }else{
        setDisabled(true)
      }
    }, [reason])
    

  return (

    <>
        <Modal isOpen={modal} toggle={toggle} className="modal-change">
        <ModalBody className="modal-body-accept">
          <button className="modal-close-button" onClick={toggle}>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM14 26C7.4 26 2 20.6 2 14C2 7.4 7.4 2 14 2C20.6 2 26 7.4 26 14C26 20.6 20.6 26 14 26Z"
                fill="#F3723C"
              />
              <path
                d="M19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z"
                fill="#F3723C"
              />
            </svg>
          </button>
          <div className="modalChange-body">
            <p className='title-modalChange'>Selecciona las opciones que quieres reclasificar</p>
            <Row>
                <Col>
                    <SelectChangeChannel setChannel={setChannel} channel={channel} detailChanel={detail.complaint_channel} detailCategorie={detail.complaint_type}/>
                </Col>
                <Col>
                    <SelectChangeCategorie setCategorie={setCategorie} categorie={categorie} channel={channel}/>
                </Col>
                <Col>
                    <SelectChangeReason setReason={setReason} reason={reason} categorie={categorie} channel={channel}/>
                </Col>
            </Row>
          </div>
          
          <div className='containerModal-btns'>
          <p className='warning'>Debes seleccionar una opción de cada filtro.</p>
            <Button className="btn-modal btn-cancel" onClick={toggle}>
              Cancelar
            </Button>
            <Button className="btn-modal btn-accept" disabled={disabled} onClick={showModal2}>
              Aceptar
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2} className="modal-accept">
        <ModalBody className="modal-body-accept">
        <button className="modal-close-button" onClick={toggle2}>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM14 26C7.4 26 2 20.6 2 14C2 7.4 7.4 2 14 2C20.6 2 26 7.4 26 14C26 20.6 20.6 26 14 26Z"
                fill="#F3723C"
              />
              <path
                d="M19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z"
                fill="#F3723C"
              />
            </svg>
          </button>
         <p className='title-modalChange'>¿Estás seguro de reclasificar? Una vez guardado los cambios, no se puede volver a editar</p>
         <div className='containerModal-btns'>
            <Button className="btn-modal btn-cancel" onClick={toggle2} disabled={disabled}>
              Cancelar
            </Button>
            <Button className="btn-modal btn-accept" disabled={disabled} onClick={sendData}>
              Aceptar
            </Button>
          </div>
        </ModalBody>
      </Modal>
        <button className='modalChange' onClick={showModal}>
            EDITAR OPCIONES
        </button>
    </>
  )
}
