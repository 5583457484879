import React, {useContext, useEffect, useState , useRef, useCallback} from 'react'
import moment from 'moment'
// import Loader from '../../components/loader/Loader';
import { contextApp } from '../../../App/contextApp';
import HttpHandlerServices from '../../../../services/httpService';
import Loader from '../../../loader/Loader';
import { toJpeg } from 'html-to-image';
import "./DownloadImageSS.scss"

const DownloadImageSS = (props) => {
    const {refPurchase} = useContext(contextApp)
    const { sendFilters, monthRangeHandler, dataIncidences } = props;
    const { selectValue, setSelectValue, sendData, currentLanguage, collapseToSS, setCollapseToSS} = useContext(contextApp);
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [loader, setLoader] = useState(false);

    const setDate = () => {
        // let begining_date = moment().add(-3,'months').format('YYYY-MM');
        let begining_date = moment().format('YYYY-MM');
        let ending_date = moment().format('YYYY-MM');
        // let startDate = moment().add(-3,'months').format('YYYY-MM');
        let startDate = moment().format('YYYY-MM');
        let endDate = moment().format('YYYY-MM');
        setStartMonth(begining_date)
        setEndMonth(ending_date)
        setSelectValue({...selectValue, ...selectValue.months = startDate +','+ endDate})
        rangeMonthsSet(begining_date, ending_date)
        monthRangeHandler(rangeMonthsSet(startDate, endDate))
    }

    const getDate = (e) => {
        let startDate = startMonth;
        let endDate = endMonth;
        if(e.target.name === "startDate"){
            startDate = moment(e.target.value).format('YYYY-MM');
            setStartMonth(startDate)
        }else{
            endDate = moment(e.target.value).format('YYYY-MM');
            setEndMonth(endDate)
        }
        setSelectValue({ ...selectValue, ...selectValue, ['months']: startMonth +','+ endMonth})
        rangeMonthsSet(startDate, endDate)
        sendFilters(startDate +','+ endDate, rangeMonthsSet(startDate, endDate));
        monthRangeHandler(rangeMonthsSet(startDate, endDate))
    }
    
    const rangeMonthsSet = (start, end) =>{
        let f1 = moment(start);
        let f2 = moment(end);
        let m = f2.diff(f1,'months');
        let _month;
        let _months;
        let _monthsLabel=[];
        let _monthsData=[];
        let range = [];
        _monthsLabel[0] = moment(f1).format('MMM YY');
        _monthsData[0] = moment(f1).format('YYYY-MM');
        for(let i=1;i<=m;i++){
            _month = moment(f1.add(1,'months'));
            _monthsLabel[i] = _month.format('MMM YY');
            _monthsData[i] = _month.format('YYYY-MM');
        }
        _months = _monthsLabel;
        range[0] = _monthsData[0];
        range[1] = _monthsData[m];
        setSelectValue({...selectValue, ...selectValue.monthsRange = _monthsData})
        setSelectValue({...selectValue, ...selectValue.monthsLabel = _months})
        return _monthsData;
    }

    const getReport = (e) =>{
        e.preventDefault();
        setLoader(true)
        const httpHandlerServices = new HttpHandlerServices();
        const endpoint = `/exportdashpdf`;
        const dataSend = {
            stores: sendData.sucursal,
            months: selectValue.monthsRange.join()
        }
        
        httpHandlerServices.postMethodFile(endpoint, dataSend).then(
            async(res) => {
                const data = await res;
                setLoader(false)
                window.open(data, '_blank');
            }
        )
    }

    useEffect(() => {
        // let begining_date = moment().add(-3,'months').format('YYYY-MM');
        let begining_date = moment().format('YYYY-MM');
        let ending_date = moment().format('YYYY-MM');
        // let startDate = moment().add(-3,'months').format('YYYY-MM');
        let startDate = moment().format('YYYY-MM');
        let endDate = moment().format('YYYY-MM');
        setStartMonth(begining_date)
        setEndMonth(ending_date)
        setSelectValue({...selectValue, ...selectValue.months = startDate +','+ endDate})
        rangeMonthsSet(begining_date, ending_date)
        setDate();
    }, [])

    const onButtonClick = useCallback(() => {
        setCollapseToSS(true)
        setLoader(true)

        if (refPurchase.current === null) {
          return
        }

        setTimeout(() => {

        toJpeg(refPurchase.current, { backgroundColor: '#ffffff', })
          .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = 'informe.jpeg'
                link.href = dataUrl
                link.click()

            setCollapseToSS(false)
            setLoader(false);
          })
          .catch((err) => {
            console.log(err)
          })
            
        }, 500);
    
      }, [refPurchase])

    return (
        <>
            {loader ? <Loader /> : null}
            {/* <a href="/" className="text_descarga" onClick={(e) => getReport(e)}>
                <h6 className="emigre text-uppercase mt-2 text-right pr-3">
                    <b>Descargar en PDF<i className="icomoon icon-save ml-2"></i></b>
                </h6>
            </a> */}
            <div ref={refPurchase}>
            </div>
            <button 
                onClick={onButtonClick} 
                disabled={!dataIncidences || dataIncidences.length <= 0 ? true : false}
                className={`${ !dataIncidences || dataIncidences.length <= 0 ? "download-image-ss-btn-disabled" : "download-image-ss-btn" }`}
                >
                    <h6 className="emigre text-uppercase mt-2 text-right pr-3">
                        <b>Descargar en JPEG<i className="icomoon icon-save ml-2"></i></b>
                    </h6>
            </button>
        </>
    )
}

export default DownloadImageSS;