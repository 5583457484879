import React, { useContext, useState } from 'react';
import { Collapse, Table, Row, Col } from 'reactstrap';
import { Line } from 'react-chartjs-2'
import { contextApp } from '../../../../App/contextApp';
import Loading from '../../../../loading/Loading';


const ProblemOrdering = (props) => {
const [collapse, setCollapse] = useState(false);
const { monthsLabel, dataIncidences, texts } = props;
const {ProductosPresentation, ProductosSabor, ProductosTemperatura, pizzaportal} = dataIncidences;
const toggle = () => setCollapse(!collapse);

const {loading, collapseToSS} = useContext(contextApp);

const getappnosirve = () => {
    if (!pizzaportal?.appnosirve) return

    const { appnosirve } = pizzaportal

    var out = appnosirve;
    if(appnosirve){
        if(appnosirve.length === 1) {
            // out = [null, appnosirve[0], null];
            out = [appnosirve[0]];
        }
    }
    return out;
}
const getnosucursal = () => {
    if (!pizzaportal?.nosucursal) return

    const { nosucursal } = pizzaportal

    var out = nosucursal;
    if(nosucursal){
        if(nosucursal.length === 1) {
            // out = [null, nosucursal[0], null];
            out = [nosucursal[0]];
        }
    }
    return out;
}
const getproblemapago = () => {
    if (!pizzaportal?.problemapago) return

    const { problemapago } = pizzaportal

    var out = problemapago;
    if(problemapago){
        if(problemapago.length === 1) {
            // out = [null, problemapago[0], null];
            out = [problemapago[0]];
        }
    }
    return out;
}
const getnopuderealizar = () => {
    if (!pizzaportal?.nopuderealizar) return

    const { nopuderealizar } = pizzaportal

    var out = nopuderealizar;
    if(nopuderealizar){
        if(nopuderealizar.length === 1) {
            // out = [null, nopuderealizar[0], null];
            out = [nopuderealizar[0]];
        }
    }
    return out;
}
const getnollegoconfirmacion = () => {
    if (!pizzaportal?.nollegoconfirmacion) return

    const { nollegoconfirmacion } = pizzaportal

    var out = nollegoconfirmacion;
    if(nollegoconfirmacion){
        if(nollegoconfirmacion.length === 1) {
            // out = [null, nollegoconfirmacion[0], null];
            out = [nollegoconfirmacion[0]];
        }
    }
    return out;
}
const getnomodificapedido = () => {
    if (!pizzaportal?.nomodificapedido) return

    const { nomodificapedido } = pizzaportal

    var out = nomodificapedido;
    if(nomodificapedido){
        if(nomodificapedido.length === 1) {
            // out = [null, nomodificapedido[0], null];
            out = [nomodificapedido[0]];
        }
    }
    return out;
}

const getMonthsLabel = () => {
    var out = monthsLabel;
    if(monthsLabel.length === 1) {
        out = ['nulo', monthsLabel[0], 'nulo' ];
    }
    return out;
}

const data = {
    labels: getMonthsLabel(),
    datasets:[
        {
        label: texts.appNotWorking,
        fill: false,
        lineTension: 0,
        backgroundColor: '#2bf30c',
        borderColor: '#2bf30c',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#2bf30c',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#2bf30c',
        pointHoverBorderColor: '#2bf30c',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 10,
        data: getappnosirve()

    },{
        label: texts.noSucursal,
        fill: false,
        lineTension: 0,
        backgroundColor: '#9ef30c',
        borderColor: '#9ef30c',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#9ef30c',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#9ef30c',
        pointHoverBorderColor: '#9ef30c',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 10,
        data: getnosucursal()

    },{
        label: texts.paymentMethodsProblems,
        fill: false,
        lineTension: 0,
        backgroundColor: '#d4f30c',
        borderColor: '#d4f30c',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#d4f30c',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#d4f30c',
        pointHoverBorderColor: '#d4f30c',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 10,
        data: getproblemapago()

    },{
        label: texts.noMachineOrder,
        fill: false,
        lineTension: 0,
        backgroundColor: '#f3d00c',
        borderColor: '#f3d00c',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#f3d00c',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#f3d00c',
        pointHoverBorderColor: '#f3d00c',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 10,
        data: getnopuderealizar()

    },{
        label: texts.orderConfirmation,
        fill: false,
        lineTension: 0,
        backgroundColor: '#f36c0c',
        borderColor: '#f36c0c',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#f36c0c',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#f36c0c',
        pointHoverBorderColor: '#f36c0c',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 10,
        data: getnollegoconfirmacion()

    },{
        label: texts.cancelModifyOrder,
        fill: false,
        lineTension: 0,
        backgroundColor: '#f3270c',
        borderColor: '#f3270c',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#f3270c',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#f3270c',
        pointHoverBorderColor: '#f3270c',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 10,
        data: getnomodificapedido()
    }
]
}
return (
<div>
    <div className='collapseHeader' onClick={toggle}>  
        <h4 className='emigre-bold text-secondary text-left text-white text-uppercase'>
            <i className="icomoon icon-producto text-white ml-3 p-1"></i> PROBLEMA AL REALIZAR EL PEDIDO <span><svg height="30" width="30" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={collapse ? "css-6q0nyr-Svg" : "css-6q0nyr-Svg closed"}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
        </h4>
    </div>
    <Collapse isOpen={collapse || collapseToSS}>
    {loading ? (
        <Loading />
        ) : null}
        <Row>
            <Col xs={12} className='text-center'>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.appNotWorking} <span className="legend badge-box__problem-ordering-appnosirve ml-2"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.noSucursal} <span className="legend badge-box__problem-ordering-nosucursal ml-2"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.paymentMethodsProblems} <span className="legend badge-box__problem-ordering-metodospago ml-2"></span></p>
            </Col>
        </Row>
        <Row>
        <Col xs={12} className='text-center'>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.noMachineOrder} <span className="legend badge-box__problem-ordering-pedidomaquina ml-2"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.orderConfirmation} <span className="legend badge-box__problem-ordering-confirmacion ml-2"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.cancelModifyOrder} <span className="legend badge-box__problem-ordering-modificar ml-2"></span></p>
                <Line data={data} legend={{display:false}}></Line>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th scope="row" className="bg-secondary text-uppercase">PROBLEMA AL REALIZAR EL PEDIDO</th>
                            {monthsLabel && monthsLabel.map((item, index) => {
                                return <th className="text-center bg-secondary" key={index}>{item}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-uppercase">{texts.appNotWorking}</td>
                            {getappnosirve() && getappnosirve().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">{texts.noSucursal}</td>
                            {getnosucursal() && getnosucursal().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">{texts.paymentMethodsProblems}</td>
                            {getproblemapago() && getproblemapago().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">{texts.noMachineOrder}</td>
                            {getnopuderealizar() && getnopuderealizar().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">{texts.orderConfirmation}</td>
                            {getnollegoconfirmacion() && getnollegoconfirmacion().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">{texts.cancelModifyOrder}</td>
                            {getnomodificapedido() && getnomodificapedido().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Collapse>
</div>
);
}

export default ProblemOrdering;