import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => {
  return (
    <div className="loading-data">
      {/* <div className="loading-data-spin iconmoon icon-gear" /> */}
      <Spinner color="warning" />
    </div>
  );
};

export default Loading;
