import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Badge } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2'
import Product from './tables/ProductTable'
import TimeTable from './tables/TimeTable'
import OtherTable from './tables/OtherTable'
import TicketTable from './tables/TicketTable'
import './DougnutCharts.scss'

const DoughnutCharts = (props) => {
    const { product, service, time, other, texts, delivery} = props;

    const [dataDelivery, setDataDelivery] = useState([])

    const [dataProduct, setDataProduct] = useState([])
    const [dataTime, setdataTime] = useState([])
    const [dataOther, setDataOther] = useState([])
    const [dataTicket, setDataTicket] = useState([])

    const [dataProductSort, setDataProductSort] = useState([])
    const [dataTimeSort, setdataTimeSort] = useState([])
    const [dataOtherSort, setDataOtherSort] = useState([])
    const [dataTicketSort, setDataTicketSort] = useState([])

    useEffect(() => {

        setDataDelivery(delivery)

        setDataProduct([
            {
                id: "temperatura",
                name:'Temperatura', 
                value: !delivery ? 0 : 
                    delivery.didi_temperatura.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_temperatura.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_temperatura.reduce((acc, cur) => acc + cur, 0) 
            },
            {
                id: "sabor",
                name:'Sabor', 
                value: !delivery ? 0 : 
                    delivery.didi_sabor.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_sabor.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_sabor.reduce((acc, cur) => acc + cur, 0) 
            },
            {
                id: "presentacion",
                name:'Presentación', 
                value: !delivery ? 0 : 
                    delivery.didi_presentacion.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_presentacion.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_presentacion.reduce((acc, cur) => acc + cur, 0) 
            },
            {
                id: "orden-incorrecta",
                name:'Orden incorrecta', 
                value: !delivery ? 0 : 
                    delivery.didi_orden_incorrecta.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_orden_incorrecta.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_orden_incorrecta.reduce((acc, cur) => acc + cur, 0) 
            },
        ])

        setdataTime([
            {
                id: "mas-40",
                name:'Más de 40 minutos', 
                value: !delivery ? 0 : 
                    delivery.didi_tiempo_40.reduce((acc, cur) => acc + cur, 0) + 
                    delivery.rappi_tiempo_40.reduce((acc, cur) => acc + cur, 0) + 
                    delivery.uber_tiempo_40.reduce((acc, cur) => acc + cur, 0) 
            },
            {
                id: "mas-60",
                name:'Más de 1 hora', 
                value: !delivery ? 0 : 
                    delivery.didi_tiempo_1hr.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_tiempo_1hr.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_tiempo_1hr.reduce((acc, cur) => acc + cur, 0) 
            },
        ])

        setDataOther([
            {
                id: "other",
                name:'Otro', 
                value: !delivery ? 0 : 
                    delivery.didi_otros.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_otros.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_otros.reduce((acc, cur) => acc + cur, 0) 
            },
        ])

        setDataTicket([
            {
                id: "problema-fac",
                name:'Problemas para facturar', 
                value: !delivery ? 0 : 
                    delivery.didi_dificultad.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_dificultad.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_dificultad.reduce((acc, cur) => acc + cur, 0) 
            },
            {
                id: "no-ticket",
                name:'No recibi Ticket', 
                value: !delivery ? 0 : 
                    delivery.didi_norecibi.reduce((acc, cur) => acc + cur, 0) +
                    delivery.rappi_norecibi.reduce((acc, cur) => acc + cur, 0) +
                    delivery.uber_norecibi.reduce((acc, cur) => acc + cur, 0) 
            },
        ])

    }, [delivery]);

    useEffect(() => {
        fullDataSorted();
    }, [dataDelivery])
    
    const fullDataSorted = () => {

        const problemProductSorted = dataProduct.sort((a,b) => b.value - a.value);
        setDataProductSort(problemProductSorted)

        const problemTimeSorted = dataTime.sort((a,b) => b.value - a.value);
        setdataTimeSort(problemTimeSorted)

        const problemOtherSorted = dataOther.sort((a,b) => b.value - a.value);
        setDataOtherSort(problemOtherSorted)

        const problemTicketSorted=dataTicket.sort((a,b) => b.value - a.value);
        setDataTicketSort(problemTicketSorted)

    }
   
    return (
        <>
            <Row>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Producto</p>
                    <Product 
                        dataProductSort={dataProductSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Ticket y facturación</p>
                    <TicketTable 
                        dataTicketsSort={dataTicketSort}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Tiempo</p>
                    <TimeTable 
                        dataTimeSort={dataTimeSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Otro</p>
                    <OtherTable 
                        dataOtherSort={dataOtherSort}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DoughnutCharts;
