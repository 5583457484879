import React, { useState, useEffect, useRef } from "react"
import { contextApp } from './contextApp.jsx'
import HttpHandlerServices from '../../services/httpService'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { toast } from 'react-toastify';

const badgeBoxColors = {
    badgeBoxProduct: "#ab2d6c",
    badgeBoxService: "#50923f",
    badgeBoxTime: "#44b9c2",
    badgeBoxOther: "#7853bb",
    badgeBoxTicket:"#FF9A62",
    badgeBoxProblemOrdering: "#d43aad",
    badgeBoxProblemPickingUpOrder: "#7a0b24",
}

const MainWrapper = (props) => {

  //const  wsClient = new W3CWebSocket('wss://wss.littlecaesars.hubcrm.net:443'); 
  //const  wsClient = new W3CWebSocket('ws://littlecaesarswss.ktbo.mx:8090');
  const  wsClient = new W3CWebSocket('wss://wss.littlemxstg.ktbo.com:443'); 
  const refPurchase = useRef(null);
  const [loading, setLoading] = useState(false);
  const [collapseToSS, setCollapseToSS] = useState(false);

  const [selectValue, setSelectValue] = useState({
        franchise: '',
        state: '',
        city: '',
        suc: '',
        months: '',
        monthsRange: '',
        monthsLabel: ''
    });
 
    const [userSession, setUserSession] = useState({});
    const [dataGeneral, setDataGeneral] = useState([]);
    const [sendData, setSendData] = useState({
        franchise: '0',
        state: '0',
        city: '0',
        sucursal: '0'
    });

    const [franchise, setFranchise] = useState('0');
    const [state, setState] = useState('0');
    const [city, setCity] = useState('0');
    const [sucursal, setSucursal] = useState('0');
 

    const [ dataCategories, setDataCategories ] = useState({
        product: 0,
        time: 0,
        service: 0,
        other: 0
    })

    const [ dataIncidences, setDataIncidences ] = useState([]);
    const [ dataCategoriesResume, setDataCategoriesResume ] = useState([]);
    const [ dataATC, setDataATC ] = useState([]);

    const [hasData, setHasData] = useState(true);


    const updateFranchises = (string) => {
        setFranchise({...franchise, id: string});
    }


    const sendFilters = (date, monthsRange) => {

        const monthsRangeStr = monthsRange.join();
        const httpHandlerServices = new HttpHandlerServices();
        const getDataResumeIncidences = '/showgeneral/summary';
        const getDataGeneral = `/generalanalysis`;
        const getDataByCategory = '/showgeneral/summarypay';
        const getDataCategories = '/showgeneral/categories';

        const dataGeneral = {
            franchise_id: sendData.franchise !== "" ? sendData.franchise : "0",
            states: sendData.state !== "" ? sendData.state : "0",
            cities: sendData.city !== "" ? sendData.city : "0",
            stores: sendData.sucursal !== "" ? sendData.sucursal : "0",
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }
        
        const dataProducto = {
            franchise_id: sendData.franchise !== "" ? sendData.franchise : "0",
            states: sendData.state !== "" ? sendData.state : "0",
            cities: sendData.city !== "" ? sendData.city : "0",
            stores: sendData.sucursal !== "" ? sendData.sucursal : "0",
            filter: 'calidad_de_producto',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }

        const dataServicio= {
            franchise_id: sendData.franchise !== "" ? sendData.franchise : "0",
            states: sendData.state !== "" ? sendData.state : "0",
            cities: sendData.city !== "" ? sendData.city : "0",
            stores: sendData.sucursal !== "" ? sendData.sucursal : "0",
            filter: 'calidad_de_servicio',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }

        const dataTiempo = {
            franchise_id: sendData.franchise !== "" ? sendData.franchise : "0",
            states: sendData.state !== "" ? sendData.state : "0",
            cities: sendData.city !== "" ? sendData.city : "0",
            stores: sendData.sucursal !== "" ? sendData.sucursal : "0",
            filter: 'tiempo_de_entrega',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }

        const dataOtros = {
            franchise_id: sendData.franchise !== "" ? sendData.franchise : "0",
            states: sendData.state !== "" ? sendData.state : "0",
            cities: sendData.city !== "" ? sendData.city : "0",
            stores: sendData.sucursal !== "" ? sendData.sucursal : "0",
            filter: 'otros',
            months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
        }
        
        const dataIncidencesResume = {
            franchise_id: sendData.franchise !== "" ? sendData.franchise : "0",
            states: sendData.state !== "" ? sendData.state : "0",
            cities: sendData.city !== "" ? sendData.city : "0",
            stores: sendData.sucursal !== "" ? sendData.sucursal : "0",
            months: monthsRangeStr
        }
        const dataCategories = {
            franchise_id: sendData.franchise !== "" ? sendData.franchise : "0",
            states: sendData.state !== "" ? sendData.state : "0",
            cities: sendData.city !== "" ? sendData.city : "0",
            stores: sendData.sucursal !== "" ? sendData.sucursal : "0",
            months: monthsRangeStr
        }
        
        

        httpHandlerServices.postMethod(getDataGeneral, dataGeneral).then(
            async(res) => {
                const data = await res;
                setDataGeneral(data)
            }
        )
        httpHandlerServices.postMethod(getDataResumeIncidences, dataIncidencesResume).then(
            async(res) => {
                const data = await res;
                setDataIncidences(data);
            }
        )
        httpHandlerServices.postMethod(getDataCategories, dataCategories).then(
            async(res) => {
                const data = await res;
                
                setDataCategoriesResume(data)
            }
        )
        httpHandlerServices.postMethod(getDataByCategory, dataProducto).then(
            async(res) => {
                const data = await res;
                
                setDataCategories({...dataCategories, ...dataCategories['product']= data});
                if(data[0]) {
                    if(data[0].total < 1) {
                        setHasData(false);
                    } else {
                        setHasData(true)
                    }      
                }
            },(error) => {
                setHasData(false);
            }
        )
        httpHandlerServices.postMethod(getDataByCategory, dataServicio).then(
            async(res) => {
                const data = await res;
                setDataCategories({...dataCategories, ...dataCategories['service']= data})
            }
        )
        httpHandlerServices.postMethod(getDataByCategory, dataTiempo).then(
            async(res) => {
                const data = await res;
                setDataCategories({...dataCategories, ...dataCategories['time']= data})
            }
        )
        httpHandlerServices.postMethod(getDataByCategory, dataOtros).then(
            async(res) => {
                const data = await res;
                setDataCategories({...dataCategories, ...dataCategories['other']= data})
            }
        )
    }

    const sendFiltersATC = () => {
        const httpHandlerServices = new HttpHandlerServices();
        const getDataATC = '/showconversations';

        const dataATCSet = {
            franchise_id: sendData.franchise,
            state_ids: sendData.state,
            locality_ids: sendData.city,
            stores_ids: sendData.sucursal,
        }

        httpHandlerServices.postMethod(getDataATC, dataATCSet).then(
            async(res) => {
                const data = await res;
                setDataATC(data)
            }
        )
    }



    useEffect(()=>{
        
        wsClient.onerror = function() {

            console.log('Connection Error');

        };
    
        wsClient.onopen = () => {


        };
    
        wsClient.onmessage = (message) => {
            
            if(message) {

                const msg = JSON.parse(message.data)
                
                toast.warning(
                    <div onClick={
                        (e) => {
                            const url = `/atencion/chat/showconversationsinit/${msg.conversation_id}`
                            var win = window.open(url, '_blank');
                            win.focus();
                        }
                    }>
                        <h5>Nuevo Mensaje</h5>
                    </div>, {
        
                        position: toast.POSITION.BOTTOM_RIGHT,
        
                        autoClose: 8000
        
                });
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
 
    const [currentLanguage] = useState('spanish');

    return (
      <>
        <contextApp.Provider value={
            { 
                badgeBoxColors,
                currentLanguage, 
                updateFranchises, 
                hasData, 
                selectValue, 
                setSelectValue, 
                userSession, 
                setUserSession, 
                dataGeneral, 
                setDataGeneral, 
                sendData, 
                setSendData, 
                dataCategories, 
                dataIncidences, 
                dataCategoriesResume, 
                sendFilters, 
                sendFiltersATC, 
                dataATC,
                loading,
                setLoading,
                refPurchase,
                collapseToSS,
                setCollapseToSS
            }}>
            <main>
                {props.children}
            </main> 
        </contextApp.Provider>
      </>
    );
}
export default MainWrapper