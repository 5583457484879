import FileSaver from 'file-saver';
//const baseUrl = 'https://littlecaesarsapi.ktbo.mx/api/auth';
// const baseUrl = 'https://api.littlecaesars.hubcrm.net/api/auth';
//const baseUrl = 'https://api.littlemxstg.ktbo.com/api/auth';
const baseUrl = 'https://api.littlestaging.hubcrm.net/api/auth';
// const baseUrl = 'https://api.littlecaesars.hubcrm.net/api/auth';

const bearerToken = () => {
    
    const session =  JSON.parse(localStorage.getItem('userSession'));
    
    if (session) {
        
        return `Bearer ${session.access_token}`;

    } else {

        return null; 

    }
    
}

export default class HttpHandlerServices { 

    postMethod(endpoint, data) {
        
        const url = baseUrl + endpoint;

        return new Promise(
            
            async (resolve, reject) => {
                
                await fetch (url, {

                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': bearerToken()
                    }

                })
                .then(

                    res => res.json()

                )
                .catch(

                    error => reject(error)

                )
                .then(

                    response => resolve(response)

                );

            }

        );
    }

    postMethodFile(endpoint, data) {
        const url = baseUrl + endpoint;
        return new Promise(
            async (resolve, reject) => {
                await fetch (url, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': bearerToken()
                    }
                })
                .then(res => {
                    return res.blob()
                })
                .catch(error => reject(error))
                .then(blob =>  {
                   return  resolve(FileSaver.saveAs(blob, 'reporte.csv'))
                });
            }
        );
    }

    getMethod (endpoint) {

        const url = baseUrl + endpoint;
    
        return new Promise(

            async (resolve, reject) => {

                await fetch(url, {

                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': bearerToken()
                    }

                })
                .then(

                    res => res.json()

                )
                .catch (

                    error => reject(error)

                )
                .then (

                    response => resolve(response)

                )

            }

        );

    }

}


/* 
    //Funcion para el consumo de los servicios solo cambiar nombre de la funcion
    //por y usar el metodo correspondiente getMethod, postMethod, etc
    const cambiarnombredefuncion = () => {

    const httpHandlerServices = new HttpHandlerServices();

    const endpoint = '/user';

    httpHandlerServices.getMethod(endpoint).then(

        async(res) => {

            const data = await res;

            console.log('res', data)

        }, async(error) => {

            const err = await error;

            console.log('___Error', err)

        }

    )

} */