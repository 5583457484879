import React from "react"
import { Pie } from 'react-chartjs-2'
import { Row, Col } from 'reactstrap'
import './pieChart.scss';
import { useContext } from "react";
import { contextApp } from "../../../../App/contextApp";

const PieChart = (props) =>{
    const { dataCategoriesResume, texts, dataIncidences } = props;
    const { mostrador } = dataIncidences;

    const { badgeBoxColors } = useContext(contextApp);

    const { 
        badgeBoxProduct,
        badgeBoxService,
        badgeBoxTime,
        badgeBoxOther,
        badgeBoxTicket
    } = badgeBoxColors;

    let data = {};

    let totalIncidents;

    let totalProductIncidents;
    let totalPercentProductIncidents;

    let totalServiceIncidents;
    let totalPercentServiceIncidents;

    let totalTimeIncidentes;
    let totalPercentTimeIncidents;

    let totalTicketIncidentes;
    let totalPercentTicketIncidents;

    let totalOtherIncidents;
    let totalPercentOtherIncidents;
    console.log(mostrador);

    if (mostrador) {
        
        // totalIncidents = Object.entries(mostrador)
        //     .map(([_,e]) => e)
        //     .map((f) => f.reduce((acc,cur) => (acc+cur), 0))
        //     .reduce((acc,cur) => acc+cur, 0);

        totalIncidents = Object.entries(mostrador)
            .map(([d,e]) => {
                if (d == "Tiempo_5sinfila" || d == "Tiempo_10sinfila" || d == "Tiempo_15sinfila") return [0]
                return e
            })
            .map((f) => f.reduce((acc,cur) => (acc+cur), 0))
            .reduce((acc,cur) => acc+cur, 0);

        totalProductIncidents = Object.entries(mostrador)
            .map(([e, f]) => {
                if(e === "ProductosPresentation") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "ProductosSabor") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "ProductosTemperatura") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentProductIncidents = totalIncidents !== 0 ? parseFloat((totalProductIncidents * 100) / totalIncidents).toFixed(1) : 0

        totalServiceIncidents = Object.entries(mostrador)
            .map(([e, f]) => {
                if(e === "CalidadOrden") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "CalidadSucursal") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "CalidadAtencion") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentServiceIncidents = totalIncidents !== 0 ? parseFloat((totalServiceIncidents * 100) / totalIncidents).toFixed(1) : 0

        totalTimeIncidentes = Object.entries(mostrador)
        .map(([e, f]) => {
            if(e === "Tiempo_5fila") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            if(e === "Tiempo_10fila") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            if(e === "Tiempo_15fila") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            // if(e === "Tiempo_5sinfila") {
            //     return f.reduce((acc, cur) => acc+cur, 0)
            // }
            // if(e === "Tiempo_10sinfila") {
            //     return f.reduce((acc, cur) => acc+cur, 0)
            // }
            // if(e === "Tiempo_15sinfila") {
            //     return f.reduce((acc, cur) => acc+cur, 0)
            // }
        })
        .reduce((acc,cur) => {
            if (typeof cur === 'number') return acc+cur
            return acc;
        }, 0)

        totalPercentTimeIncidents = totalIncidents !== 0 ? parseFloat((totalTimeIncidentes * 100) / totalIncidents).toFixed(1) : 0 

        totalTicketIncidentes = Object.entries(mostrador)
            .map(([e,f])=>{
                if(e === "Dificultad") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "noRecibi") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)
        totalPercentTicketIncidents=totalIncidents !== 0 ? parseFloat((totalTicketIncidentes * 100) / totalIncidents).toFixed(1) : 0 
        
        totalOtherIncidents = Object.entries(mostrador)
            .map(([e, f]) => {
                if(e === "Otros") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentOtherIncidents = totalIncidents !== 0 ? parseFloat((totalOtherIncidents * 100) / totalIncidents).toFixed(1) : 0

        data = {
                labels: [texts?.product, texts?.service, texts?.time, texts?.other, texts?.ticket],
                datasets: [
                    {  
                        label: '%',
                        backgroundColor: [badgeBoxProduct, badgeBoxService, badgeBoxTime,badgeBoxTicket, badgeBoxOther],
                        data: [
                            totalPercentProductIncidents,
                            totalPercentServiceIncidents,
                            totalPercentTimeIncidents,
                            totalPercentTicketIncidents,
                            totalPercentOtherIncidents
                        ],
                    }
                ] 
            }

    }
    
    return( <>
                <Row className="mb-4">
                    <Col xs={12} className="text-center mb-4">
                        <div className="d-flex justify-content-md-center justify-content-end align-items-center"><h5 className="mr-2">{texts.totalIncidents}</h5><span className="grayCapsule w-auto ml-1 d-inline-block">{!totalIncidents ? 0 : totalIncidents}</span></div>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="text-right left-bar">
                        <p className="d-flex justify-content-end align-items-center"><span>{texts.product}</span> <div className="mx-2"><span className="legend badge-box__product "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentProductIncidents}%</span></div></p>
                        <p className="number formatext d-flex justify-content-end align-items-center"><span>{texts.service}</span> <div className="mx-2"><span className="legend badge-box__service "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentServiceIncidents}%</span></div></p>
                        <p className="number formatext d-flex justify-content-end align-items-center"><span>{texts.time}</span> <div className="mx-2"><span className="legend badge-box__time"></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentTimeIncidents}%</span></div></p>
                        <p className="number formatext d-flex justify-content-end align-items-center"><span>{texts.ticket}</span> <div className="mx-2"><span className="legend badge-box__ticket"></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentTicketIncidents}%</span></div></p>
                        <p className="d-flex justify-content-end align-items-center"><span>{texts.other}</span> <div className="mx-2"><span className="legend badge-box__other"></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentOtherIncidents}%</span></div></p>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="d-flex align-items-center">
                        {
                            totalIncidents !== 0 ?
                            <Pie data={data} legend={{display:false}}></Pie> :
                            <h2 className="text-center">No hay datos disponibles</h2>
                        }
                        
                    </Col>
                </Row>
            </>
        )
}

export default PieChart;