import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import language from "./language.json";
import "./Panel.scss";
import ComplaintsByFranchiseTable from "./tables/ComplaintsByFranchiseTable/ComplaintsByFranchiseTable";
import ComplaintsByStoreTable from "./tables/ComplaintsByStoreTable/ComplaintsByStoreTable";
import GeneralStatistics from "./tabs/GeneralStatistics/GeneralStatistics";
import PurchaseChannelStatistics from "./tabs/PurchaseChannelStatistics/PurchaseChannelStatistics";
import ChartOfComplaintsWithCommentsByStatus from "./charts/ChartOfComplaintsWithCommentsByStatus/ChartOfComplaintsWithCommentsByStatus";
import ChartOfComplaintsWithoutCommentsByStatus from "./charts/ChartOfComplaintsWithoutCommentsByStatus/ChartOfComplaintsWithoutCommentsByStatus";
import Filters from "../../Filters/Filters";
import DatePicker from "../../DatePicker/DatePicker";
import Layout from "../../layout/layout";
import { contextApp } from "../../App/contextApp";
import HttpHandlerServices from "../../../services/httpService";
import DownloadImageSS from "./DownloadImageSS/DownloadImageSS";
import DownloadCSV from "./DownloadCSV/DownloadCSV";
import Loader from "../../loader/Loader";

const Panel = () => {
  const { sendFilters, hasData, currentLanguage, loading, setLoading } =
    useContext(contextApp);

  const [texts, setTexts] = useState(language.english);

  useEffect(() => {
    if (currentLanguage === "spanish") {
      setTexts(language.spanish);
    }
  }, [currentLanguage]);

  // const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(true);

  const [isLoaded, setIsLoaded] = useState(false);

  const [activeTab, setActiveTab] = useState("1");

  const [activeTab1, setActiveTab1] = useState(true);

  const [activeTab2, setActiveTab2] = useState(false);

  const [subActiveTab, setSubActiveTab] = useState("1");

  const [subActiveTab1, setSubActiveTab1] = useState(true);

  const [subActiveTab2, setSubActiveTab2] = useState(false);

  const [subActiveStatisticsTab, setSubActiveStatisticsTab] = useState("1");

  const [subActiveStatisticsTab1, setSubActiveStatisticsTab1] = useState(true);

  const [subActiveStatisticsTab2, setSubActiveStatisticsTab2] = useState(false);

  const [dataFilters, setDataFilter] = useState({
    franchise: "0",
    state: "0",
    city: "0",
    sucursal: "0",
  });

  const [monthsRange, setMonthsRange] = useState("");

  const [dataGeneral, setDataGeneral] = useState([]);

  const [graphicsSum, setGraphicsSum] = useState({
    comentario_nuevas: null,
    comentario_contestando_crm: null,
    comentario_contestando_fb: null,
    comentario_cerradas_crm: null,
    comentario_cerradas_fb_crm: null,
    comentario_cerradas_fb_usuario: null,
    sincomentario_nuevas: null,
    sincomentario_contestando_fb: null,
    sincomentario_contestando_crm: null,
    sincomentario_cerrada_fb: null,
  });

  const [dataIncidences, setDataIncidences] = useState([]);

  const [dataCategoriesResume, setDataCategoriesResume] = useState([]);

  const [dataCategories, setDataCategories] = useState({
    product: 0,
    time: 0,
    service: 0,
    other: 0,
  });

  const [refetch, seTrefetch] = useState(false);

  const [firstFetch, setfirstFetch] = useState(true);

  const [exportFile, setExportFile] = useState(true);

  const onRefetch = () => seTrefetch((prev) => !prev);

  const onRefetchExport = () => setExportFile((prev) => !prev);

  const monthRangeHandler = (range) => {
    setMonthsRange(range);
  };

  const filterHandler = (name, value) => {
    if (name === "franchise") {
      setDataFilter({ ...dataFilters, franchise: value });
    }

    if (name === "state") {
      setDataFilter({ ...dataFilters, state: value });
    }

    if (name === "city") {
      setDataFilter({ ...dataFilters, city: value });
    }

    if (name === "sucursal") {
      setDataFilter({ ...dataFilters, sucursal: value });
    }
  };

  React.useEffect(() => {
    if (monthsRange[0] && firstFetch) {
      getFullData();
      setfirstFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthsRange]);

  React.useEffect(() => {
    if (monthsRange[0] && !firstFetch) {
      setLoading(true);
      getFullData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  React.useEffect(() => {
    if (hasData) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [hasData]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleSubTabs = (tab) => {
    if (subActiveTab !== tab) setSubActiveTab(tab);
  };

  const toggleSubStatisticsTabs = (tab) => {
    if (subActiveStatisticsTab !== tab) setSubActiveStatisticsTab(tab);
  };

  const getFullData = () => {
    setIsLoaded(false);
    // setLoading(true);
    /* const getFullDataEndpoint = `/showstoresfull`; */
    console.log(monthsRange, "----");
    const getDataGeneralEndPoint = `/generalanalysis`;
    const dataGeneral = {
      franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
      states: dataFilters.state !== "" ? dataFilters.state : "0",
      cities: dataFilters.city !== "" ? dataFilters.city : "0",
      stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
      months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`,
    };

    const getDataCategoriesEndPoint = "/showgeneral/categories";
    const dataCategories = {
      franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
      states: dataFilters.state !== "" ? dataFilters.state : "0",
      cities: dataFilters.city !== "" ? dataFilters.city : "0",
      stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
      months: monthsRange.join(),
    };

    const getDataResumeIncidencesEndPoint = "/showgeneral/summary";
    const dataIncidencesResume = {
      franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
      states: dataFilters.state !== "" ? dataFilters.state : "0",
      cities: dataFilters.city !== "" ? dataFilters.city : "0",
      stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
      months: monthsRange.join(),
    };

    const getDataByCategory = "/showgeneral/summarypay";
    const dataProducto = {
      franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
      states: dataFilters.state !== "" ? dataFilters.state : "0",
      cities: dataFilters.city !== "" ? dataFilters.city : "0",
      stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
      filter: "calidad_de_producto",
      months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`,
    };

    const dataServicio = {
      franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
      states: dataFilters.state !== "" ? dataFilters.state : "0",
      cities: dataFilters.city !== "" ? dataFilters.city : "0",
      stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
      filter: "calidad_de_servicio",
      months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`,
    };

    const dataTiempo = {
      franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
      states: dataFilters.state !== "" ? dataFilters.state : "0",
      cities: dataFilters.city !== "" ? dataFilters.city : "0",
      stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
      filter: "tiempo_de_entrega",
      months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`,
    };

    const dataOtros = {
      franchise_id: dataFilters.franchise !== "" ? dataFilters.franchise : "0",
      states: dataFilters.state !== "" ? dataFilters.state : "0",
      cities: dataFilters.city !== "" ? dataFilters.city : "0",
      stores: dataFilters.sucursal !== "" ? dataFilters.sucursal : "0",
      filter: "otros",
      months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`,
    };

    const httpHandlerServices = new HttpHandlerServices();

    /*     httpHandlerServices
      // .getMethod(`/showstoresfull`)
      .postMethod(`/showconversations`)
      .then(async (res) => {
        const data = await res;

      })
      .catch((err) => console.log("getMethodError: ", err.message)); */

    httpHandlerServices
      .postMethod(getDataGeneralEndPoint, dataGeneral)
      .then(async (res) => {
        const data = await res;

        setDataGeneral(data);

        setGraphicsSum({
          ...graphicsSum,
          comentario_nuevas: data.franchise_list
            .map((e) => e.comentario_nuevas)
            .reduce((acc, cur) => acc + cur, 0),
          comentario_contestando_crm: data.franchise_list
            .map((e) => e.comentario_contestando_crm)
            .reduce((acc, cur) => acc + cur, 0),
          comentario_contestando_fb: data.franchise_list
            .map((e) => e.comentario_contestando_fb)
            .reduce((acc, cur) => acc + cur, 0),
          comentario_cerradas_crm: data.franchise_list
            .map((e) => e.comentario_cerradas_crm)
            .reduce((acc, cur) => acc + cur, 0),
          comentario_cerradas_fb_crm: data.franchise_list
            .map((e) => e.comentario_cerradas_fb_crm)
            .reduce((acc, cur) => acc + cur, 0),
          comentario_cerradas_fb_usuario: data.franchise_list
            .map((e) => e.comentario_cerradas_fb_usuario)
            .reduce((acc, cur) => acc + cur, 0),
          sincomentario_nuevas: data.franchise_list
            .map((e) => e.sincomentario_nuevas)
            .reduce((acc, cur) => acc + cur, 0),
          sincomentario_contestando_fb: data.franchise_list
            .map((e) => e.sincomentario_contestando_fb)
            .reduce((acc, cur) => acc + cur, 0),
          sincomentario_contestando_crm: data.franchise_list
            .map((e) => e.sincomentario_contestando_crm)
            .reduce((acc, cur) => acc + cur, 0),
          sincomentario_cerrada_fb: data.franchise_list
            .map((e) => e.sincomentario_cerrada_fb)
            .reduce((acc, cur) => acc + cur, 0),
        });
      });

    httpHandlerServices
      .postMethod(getDataCategoriesEndPoint, dataCategories)
      .then(async (res) => {
        const data = await res;
        setDataCategoriesResume(data);
      });

    httpHandlerServices
      .postMethod(getDataResumeIncidencesEndPoint, dataIncidencesResume)
      .then(async (res) => {
        const data = await res;
        setDataIncidences(data);
        setIsLoaded(true);
        setLoading(false);
      });

    httpHandlerServices.postMethod(getDataByCategory, dataProducto).then(
      async (res) => {
        const data = await res;

        setDataCategories({
          ...dataCategories,
          ...(dataCategories["product"] = data),
        });
      },
      (error) => {
        //setHasData(false);
      }
    );

    httpHandlerServices
      .postMethod(getDataByCategory, dataServicio)
      .then(async (res) => {
        const data = await res;

        setDataCategories({
          ...dataCategories,
          ...(dataCategories["service"] = data),
        });
      });

    httpHandlerServices
      .postMethod(getDataByCategory, dataTiempo)
      .then(async (res) => {
        const data = await res;

        setDataCategories({
          ...dataCategories,
          ...(dataCategories["time"] = data),
        });
        setDataCategories({
          ...dataCategories,
          ...(dataCategories["time"] = data),
        });
      });

    httpHandlerServices
      .postMethod(getDataByCategory, dataOtros)
      .then(async (res) => {
        const data = await res;
        setDataCategories({
          ...dataCategories,
          ...(dataCategories["other"] = data),
        });
        setDataCategories({
          ...dataCategories,
          ...(dataCategories["other"] = data),
        });
      });
  };

  const getReport = (e) => {
    e.preventDefault();
    setLoading(true);
    const httpHandlerServices = new HttpHandlerServices();
    const endpoint = `/export`;
    console.log(dataFilters);
    const dataSend = {
      stores: dataFilters.sucursal,
      franchise_id:dataFilters.franchise,
      states:dataFilters.state,
      months: monthsRange.join(),
    };

    console.log("datasend", dataSend);
    httpHandlerServices.postMethodFile(endpoint, dataSend).then(async (res) => {
      const data = await res;
      setLoading(false);
      window.open(data, "_blank");
    });
  };

  return (
    <Layout>
      <div className="overview">
        {loading && <Loader />}
        {/* <DatePicker
          sendFilters={sendFilters}
          monthRangeHandler={monthRangeHandler}
          loading={loading}
          setLoading={setLoading}
        /> */}
        <Filters
          type={"dashboard"}
          filterHandler={filterHandler}
          dataFilters={dataFilters}
          setDataFilter={setDataFilter}
          sendFilters={sendFilters}
          monthRangeHandler={monthRangeHandler}
          loading={loading}
          setLoading={setLoading}
          onRefetch={onRefetch}
          exportFile={exportFile}
        />
        <Row className="max-width1440-center">
          <a className="text_descarga" onClick={(e) => getReport(e)}>
            <h6 className="emigre text-uppercase mt-2 text-right border-secondary pr-3">
              <p className="ml-8 mb-8 cursor-down">
                {texts.downloadReport}{" "}
                <i className="icomoon icon-save ml-2 black-icon"></i>
              </p>
            </h6>
          </a>
        </Row>
        <Container>
          <div className="tabs">
            <Nav tabs>
              <NavItem
                className={
                  activeTab1
                    ? "overviewTabs emigre-bold text-secondary activeTabs"
                    : "overviewTabs emigre-bold text-secondary"
                }
              >
                <NavLink
                  className={classnames(
                    { active: activeTab === "1" } &&
                      "emigre-bold text-secondary text-center"
                  )}
                  onClick={() => {
                    toggle("1");
                    setActiveTab1(true);
                    setActiveTab2(false);
                  }}
                >
                  {texts?.generalViewTab}
                </NavLink>
              </NavItem>
              <NavItem
                className={
                  activeTab2
                    ? "overviewTabs emigre-bold text-secondary activeTabs"
                    : "overviewTabs emigre-bold text-secondary"
                }
              >
                <NavLink
                  className={classnames(
                    { active: activeTab === "2" } &&
                      "emigre-bold text-secondary text-center"
                  )}
                  onClick={() => {
                    toggle("2");
                    setActiveTab1(false);
                    setActiveTab2(true);
                  }}
                >
                  {texts?.franchiseView}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Container>
                  <div className="tabs">
                    <Nav tabs>
                      <NavItem
                        className={
                          subActiveStatisticsTab1
                            ? "overviewSubTabs emigre-bold text-secondary subActiveTabs"
                            : "overviewSubTabs emigre-bold text-secondary"
                        }
                      >
                        <NavLink
                          className={classnames(
                            { active: subActiveStatisticsTab === "1" } &&
                              subActiveStatisticsTab1
                              ? "subActiveLink emigre-bold text-secondary text-center"
                              : "emigre-bold text-secondary text-center"
                          )}
                          onClick={() => {
                            toggleSubStatisticsTabs("1");
                            setSubActiveStatisticsTab1(true);
                            setSubActiveStatisticsTab2(false);
                          }}
                        >
                          Estadísticas Generales
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          subActiveStatisticsTab2
                            ? "overviewSubTabs emigre-bold text-secondary subActiveTabs mr-auto"
                            : "overviewSubTabs emigre-bold text-secondary"
                        }
                      >
                        <NavLink
                          className={classnames(
                            { active: subActiveStatisticsTab === "2" } &&
                              subActiveStatisticsTab2
                              ? "subActiveLink emigre-bold text-secondary text-center"
                              : "emigre-bold text-secondary text-center"
                          )}
                          onClick={() => {
                            toggleSubStatisticsTabs("2");
                            setSubActiveStatisticsTab1(false);
                            setSubActiveStatisticsTab2(true);
                          }}
                        >
                          Estadísticas por Canal de Compra
                        </NavLink>
                      </NavItem>
                      <div
                        className={
                          subActiveStatisticsTab2
                            ? "d-flex align-items-center"
                            : "d-none"
                        }
                      >
                        <DownloadImageSS
                          sendFilters={sendFilters}
                          monthRangeHandler={monthRangeHandler}
                          dataIncidences={dataIncidences}
                        />
                        <DownloadCSV
                          sendFilters={sendFilters}
                          monthRangeHandler={monthRangeHandler}
                        />
                      </div>
                    </Nav>
                    <TabContent activeTab={subActiveStatisticsTab}>
                      <TabPane tabId={"1"}>
                        <Row>
                          <Col sm="12">
                            <GeneralStatistics
                              dataGeneral={dataGeneral}
                              dataCategoriesResume={dataCategoriesResume}
                              dataIncidences={dataIncidences}
                              dataCategories={dataCategories}
                              loading={loading}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={"2"}>
                        <PurchaseChannelStatistics
                          dataCategoriesResume={dataCategoriesResume}
                          dataCategories={dataCategories}
                          dataIncidences={dataIncidences}
                          isLoaded={isLoaded}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Container>
              </TabPane>
              <TabPane tabId="2">
                <Container>
                  <div className="tabs">
                    <Nav tabs>
                      <NavItem
                        className={
                          subActiveTab1
                            ? "overviewSubTabs emigre-bold text-secondary subActiveTabs"
                            : "overviewSubTabs emigre-bold text-secondary"
                        }
                      >
                        <NavLink
                          className={classnames(
                            { active: subActiveTab === "1" } && subActiveTab1
                              ? "subActiveLink emigre-bold text-secondary text-center"
                              : "emigre-bold text-secondary text-center"
                          )}
                          onClick={() => {
                            toggleSubTabs("1");
                            setSubActiveTab1(true);
                            setSubActiveTab2(false);
                          }}
                        >
                          {/* {texts?.generalViewTab} */}
                          DESGLOSE POR FRANQUICIA
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          subActiveTab2
                            ? "overviewSubTabs emigre-bold text-secondary subActiveTabs"
                            : "overviewSubTabs emigre-bold text-secondary"
                        }
                      >
                        <NavLink
                          className={classnames(
                            { active: subActiveTab === "2" } && subActiveTab2
                              ? "subActiveLink emigre-bold text-secondary text-center"
                              : "emigre-bold text-secondary text-center"
                          )}
                          onClick={() => {
                            toggleSubTabs("2");
                            setSubActiveTab1(false);
                            setSubActiveTab2(true);
                          }}
                        >
                          {/* {texts?.generalViewTab} */}
                          DESGLOSE POR SUCURSAL
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={subActiveTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Container>
                            <Col sm="6">
                              <h4 className="emigre-bold text-secondary">
                                {texts.complainsPerFranchise}
                              </h4>
                            </Col>

                            <Col sm="12">
                              <ComplaintsByFranchiseTable
                                // colors={colors}
                                dataGeneral={dataGeneral}
                                loading={loading}
                              />
                            </Col>
                          </Container>
                        </Row>

                        <Row className="my-5">
                          <Container>
                            <Col sm="6">
                              {/* <h4 className='emigre-bold text-secondary'>{texts.complainsPerFranchise}</h4> */}
                              <h4 className="emigre-bold text-secondary">
                                GRÁFICAS DE QUEJAS CON COMENTARIO POR STATUS
                              </h4>
                            </Col>
                            <Col sm="12">
                              <ChartOfComplaintsWithCommentsByStatus
                                loading={loading}
                                graphicsSum={graphicsSum}
                              />
                            </Col>
                          </Container>
                        </Row>

                        <Row className="my-5">
                          <Container>
                            <Col sm="6">
                              {/* <h4 className='emigre-bold text-secondary'>{texts.complainsPerFranchise}</h4> */}
                              <h4 className="emigre-bold text-secondary">
                                GRÁFICAS DE QUEJAS SIN COMENTARIO POR STATUS
                              </h4>
                            </Col>

                            <Col sm="12">
                              <ChartOfComplaintsWithoutCommentsByStatus
                                loading={loading}
                                graphicsSum={graphicsSum}
                              />
                            </Col>
                          </Container>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Col sm="6">
                          <h4 className="emigre-bold text-secondary">
                            TABLA DE QUEJAS POR SUCURSAL
                          </h4>
                        </Col>

                        <Col sm="12">
                          <ComplaintsByStoreTable
                            // colors={colors}
                            dataGeneral={dataGeneral}
                            loading={loading}
                          />
                        </Col>
                      </TabPane>
                    </TabContent>
                  </div>
                </Container>
              </TabPane>
            </TabContent>
          </div>
        </Container>
        )
      </div>
    </Layout>
  );
};

export default Panel;
