


export function mostradorReason(){

    const dataMostradorCalidadServicio=[
        {
            label:"Orden incorrecta",
            value:"orden_incorrecta"
        },
        {
            label:"Sucursal Sucia",
            value:"sucursal_sucia"
        },
        {
            label:"Atencion del personal",
            value:"atencion_personal"
        },
    ]
    
    const dataMostradorCalidadProducto=[
        {
            label:"Temperatura",
            value:"temperatura"
        },
        {
            label:"Sabor",
            value:"sabor"
        },
        {
            label:"Presentación",
            value:"presentacion"
        },
    ]
    
    const dataMostradorTiempo=[
        {
            label:"Más de 5 min",
            value:"mas_5"
        },
        {
            label:"Más de 10 min",
            value:"mas_10"
        },
        {
            label:"Más de 15 min",
            value:"mas_15"
        },
    ]
    
    const dataMostradorTicketFacturacion=[
        {
            label:"No recibi ticket",
            value:"no_recibi_ticket"
        },
        {
            label:"Dificultad para facturar",
            value:"dificultad_para_facturar"
        },
    ]

    return {
        dataMostradorTicketFacturacion,
        dataMostradorCalidadServicio,
        dataMostradorTiempo,
        dataMostradorCalidadProducto
    }
}

export function pizzaPortalReason(){
    const dataPizzaProducto=[
        {
            label:"Temperatura",
            value:"temperatura"
        },
        {
            label:"Sabor",
            value:"sabor"
        },
        {
            label:"Presentación",
            value:"presentacion"
        },
    ]

    const dataPizzaServicio=[
        {
            label:"Sucursal Sucia",
            value:"sucursal_sucia"
        },
        {
            label:"Atencion del personal",
            value:"atencion_personal"
        },
    ]

    const dataPizzaPedido=[
        {
            label:"App / Web no funciona",
            value:"appnosirve"
        },
        {
            label:"No encontré la sucursal deseada",
            value:"nosucursal"
        },
        {
            label:"Problemas con método de pago",
            value:"problemapago"
        },
        {
            label:"No pude realizar mi pedido",
            value:"nopuderealizar"
        },
        {
            label:"No llegó mi confirmación de pedido",
            value:"nollegoconfirmacion"
        },
        {
            label:"No pude cancelar o modificar el pedido",
            value:"nomodificapedido"
        }
    ]

    const dataPizzaRecoger=[
        {
            label:"No funciona el QR",
            value:"nofuncionaqr"
        },
        {
            label:"Me entregaron orden incorrecta",
            value:"orden_incorrecta"
        },
        {
            label:"No sirvió la máquina",
            value:"nosirviomaquina"
        },
        {
            label:"Mi orden no estaba lista en la máquina",
            value:"orden_nolista"
        },
        {
            label:"Se asignó el pedido a una sucursal equivocada",
            value:"sucursal_equivocada"
        },
    ]

    const dataPizzaTicketFacturacion=[
        {
            label:"No recibi ticket",
            value:"no_recibi_ticket"
        },
        {
            label:"Dificultad para facturar",
            value:"dificultad_para_facturar"
        },
    ]

    return {
        dataPizzaTicketFacturacion,
        dataPizzaPedido,
        dataPizzaRecoger,
        dataPizzaServicio,
        dataPizzaProducto
    }
}

export function delivery(){
    const dataDeliveryProducto=[
        {
            label:"Temperatura",
            value:"temperatura"
        },
        {
            label:"Sabor",
            value:"sabor"
        },
        {
            label:"Presentación",
            value:"presentacion"
        },
        {
            label:"Orden incorrecta",
            value:"orden_incorrecta"
        },
    ]

    const dataDeliveryTiempo=[
        {
            label:"Más de 40 mins",
            value:"tiempo_40"
        },
        {
            label:"Más de 1 hora",
            value:"tiempo_1hr"
        },
    ]

    const dataDeliveryTicketFacturacion=[
        {
            label:"No recibi ticket",
            value:"no_recibi_ticket"
        },
        {
            label:"Dificultad para facturar",
            value:"dificultad_para_facturar"
        },
    ]

    return {
        dataDeliveryProducto,
        dataDeliveryTiempo,
        dataDeliveryTicketFacturacion
    }

}