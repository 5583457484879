import React from "react";

import MaterialTable from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const colors = {
  // black: "#101010",
  black: "#191919",
  blackLight: "#21130d",
  gray: "#404040",
  grayLight: "#717171",
};

const tableOne = [
  { field: "name", title: "Franquicia" },
  { field: "Total", title: "Total de queja" },
  { field: "comentario", title: "Quejas con comentario" },
  { field: "comentario_nuevas", title: "Quejas nuevas sin asignar" },
  { field: "comentario_contestando_crm", title: "Contestando Por CRM" },
  { field: "comentario_contestando_fb", title: "Contestando Por Facebook" },
  { field: "comentario_cerradas_crm", title: "Cerradas Por CRM" },
  {
    field: "comentario_cerradas_fb_crm",
    title: "Cerradas Facebook Inactividad de CRM",
  },
  {
    field: "comentario_cerradas_fb_usuario",
    title: "Cerradas Facebook Inactividad del Usuario",
  },
  { field: "sincomentario", title: "Quejas sin comentario" },
  { field: "sincomentario_nuevas", title: "Quejas nuevas sin asignar" },
  { field: "sincomentario_contestando_crm", title: "Contestando por CRM" },
  {
    field: "sincomentario_contestando_fb",
    title: "Contestando por facebook",
  },
  { field: "sincomentario_cerrada_fb", title: "Cerrada por facebook" },
];

const columnsTableOne = [
  { color: colors.black, name: "Franquicia" },
  { color: colors.black, name: "Total de queja" },
  { name: "Quejas con comentario" },
  { name: "Quejas nuevas sin asignar" },
  {
    name: "Contestando",
    subRows: [{ name: "Por CRM" }, { name: "Por Facebook" }],
  },
  {
    name: "Cerradas",
    subRows: [
      { name: "Por CRM" },
      { name: "FB inactividad de CRM" },
      { name: "FB inactividad del usuario" },
    ],
  },
  { color: colors.grayLight, name: "Quejas sin comentario" },
  { color: colors.grayLight, name: "Quejas nuevas sin asignar" },
  // { color: colors.grayLight, name: "Contestando por facebook" },
  {
    color: colors.grayLight,
    name: "Contestando",
    subRows: [{ name: "Por CRM" }, { name: "Por Facebook" }],
  },
  { color: colors.grayLight, name: "Cerrada por facebook" },
];

const ComplaintsByFranchiseTable = React.memo(({ loading, dataGeneral }) => {

  return (
    <MaterialTable
      isLoading={loading}
      title={""}
      columns={tableOne}
      // data={data}
      data={dataGeneral.franchise_list}
      options={{
        filtering: true,
        exportButton: true,
        exportAllData: true,
        exportFileName: "TABLA DE QUEJAS POR FRANQUICIA",
        headerStyle: {
          backgroundColor: "#424242",
          color: "#FFF",
          textAlign: "center",
          fontWeight: "Bold",
        },
      }}
      components={{
        Header: (props) => {
          return (
            <TableHead
              style={{
                backgroundColor: "#424242",
                color: "#FFF",
                textAlign: "center",
                fontWeight: "Bold",
              }}
            >
              <TableRow>
                {columnsTableOne.map((data) => {
                  if (!data.subRows) {
                    return (
                      <TableCell
                        className="text-white font-weight-bold text-center border"
                        colSpan={1}
                        style={{
                          backgroundColor: data.color,
                        }}
                      >
                        {data.name}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        className="text-white font-weight-bold text-center border p-0"
                        colSpan={data.subRows.length}
                        style={{
                          backgroundColor: data.color,
                        }}
                      >
                        <TableRow className="">
                          <TableCell
                            className="text-white font-weight-bold text-center border-bottom-0"
                            colSpan={data.subRows.length}
                          >
                            {data.name}
                          </TableCell>
                        </TableRow>
                        <TableRow className="">
                          {data.subRows.map((row) => (
                            <TableCell
                              className={`text-white font-weight-bold text-center border`}
                              style={{
                                height: "130px",
                                width: 100 / data.subRows.length + "%",
                              }}
                            >
                              {row.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
          );
        },
        Row: ({ data }) => {
          return (
            <TableRow>
              <TableCell className="table-cell-border">{data.name}</TableCell>
              <TableCell className="table-cell-border">{data.Total}</TableCell>
              <TableCell className="table-cell-border">
                {data.comentario}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.comentario_nuevas}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.comentario_contestando_crm}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.comentario_contestando_fb}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.comentario_cerradas_crm}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.comentario_cerradas_fb_crm}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.comentario_cerradas_fb_usuario}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.sincomentario}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.sincomentario_nuevas}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.sincomentario_contestando_crm}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.sincomentario_contestando_fb}
              </TableCell>
              <TableCell className="table-cell-border">
                {data.sincomentario_cerrada_fb}
              </TableCell>
            </TableRow>
          );
        },
      }}
    />
  );
});

export default ComplaintsByFranchiseTable;
