import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../Sections/Login/Login"
import Panel from '../Sections/Overview/Panel';
import Services from "../Sections/Customer/Services";
import Chat from './../Sections/Chat/Chat'
import ProtectedRoute from "./ProtectedRoute"
import NewPassword from "../Sections/NewPassword/NewPassword";
import RecoverPassword from '../Sections/RecoverPassword/RecoverPassword';
import Users from "../Sections/Users/Users";
import Stores from '../Sections/Stores/Stores';
import InputPassword from '../Sections/RecoverPassword/InputPassword';
import Mantenimiento from '../mantenimiento/Mantenimiento';


const Router = (props) => (
  <>
    <Switch>

      <Route path="/" exact component={Login}/>
      
     <Route path="/login" exact component={Login}/>
      
      <Route path="/recover-password/:hash" exact component={InputPassword}/>
      
      <ProtectedRoute path="/home" exact component={Panel}/>
      
      <ProtectedRoute path="/atencion" exact component={Services}/>
      
      <ProtectedRoute path="/users" exact component={Users}/>
      
      <ProtectedRoute path="/stores" exact component={Stores}/>
      
      <ProtectedRoute path="/atencion/chat/:_conversation/:_id/:ktbos_id" exact component={Chat}/>
      
      <ProtectedRoute path="/new-password" exact component={NewPassword}/>
      
      <Route path="/recover-password" exact component={RecoverPassword}/>

      <Route path="/mantenimiento" component={Mantenimiento}/>
      
    </Switch>
  </>
  );
  export default Router;