import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Badge } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2'
import ProblemOrderingTable from './tables/ProblemOrderingTable'
import ProblemPickingOrderTable from './tables/ProblemPickingOrderTable'
import Product from './tables/ProductTable'
import ServiceTable from './tables/ServiceTable'
import OtherTable from './tables/OtherTable'
import TicketTable from './tables/TicketTable'
import './DougnutCharts.scss'

const DoughnutCharts = (props) => {
    const { product, service, time, other, texts, pizzaPortal} = props;

    const [dataPizzaPortal, setDataPizzaPortal] = useState([])

    const [dataProblemOrdering, setDataProblemOrdering] = useState([])
    const [dataProblemPickingUpOrder, setdataProblemPickingUpOrder] = useState([])
    const [dataProduct, setDataProduct] = useState([])
    const [dataService, setDataService] = useState([])
    const [dataOther, setDataOther] = useState([])
    const [dataTicket, setDataTicket] = useState([])

    const [dataProblemOrderingSort, setDataProblemOrderingSort] = useState([])
    const [dataProblemPickingUpOrderSort, setdataProblemPickingUpOrderSort] = useState([])
    const [dataProductSort, setDataProductSort] = useState([])
    const [dataServiceSort, setDataServiceSort] = useState([])
    const [dataOtherSort, setDataOtherSort] = useState([])
    const [dataTicketSort, setDataTicketSort] = useState([])

    useEffect(() => {

        setDataPizzaPortal(pizzaPortal)

        setDataProblemOrdering([
            {id: "appnosirve", name:'App/Web no funciona', value: !pizzaPortal ? 0 : pizzaPortal.appnosirve.reduce((acc, cur) => acc + cur, 0)},
            {id: "nosucursal", name:'No encontré la sucursal deseada', value: !pizzaPortal ? 0 : pizzaPortal.nosucursal.reduce((acc, cur) => acc + cur, 0)},
            {id: "metodospago", name:'Problemas con métodos de pago', value: !pizzaPortal ? 0 : pizzaPortal.problemapago.reduce((acc, cur) => acc + cur, 0)},
            {id: "pedidomaquina", name:'No pude realizar mi pedido', value: !pizzaPortal ? 0 : pizzaPortal.nopuderealizar.reduce((acc, cur) => acc + cur, 0)},
            {id: "confirmacion", name:'No llegó mi confirmación de pedido', value: !pizzaPortal ? 0 : pizzaPortal.nollegoconfirmacion.reduce((acc, cur) => acc + cur, 0)},
            {id: "modificar", name:'No pude cancelar o modificar el pedido', value: !pizzaPortal ? 0 : pizzaPortal.nomodificapedido.reduce((acc, cur) => acc + cur, 0)},
        ])

        setdataProblemPickingUpOrder([
            {id: "noqr", name:'No funciona el QR', value: !pizzaPortal ? 0 : pizzaPortal.nofuncionaqr.reduce((acc, cur) => acc + cur, 0)},
            {id: "incorrecta", name:'Me entregaron orden incorrecta', value: !pizzaPortal ? 0 : pizzaPortal.orden_incorrecta.reduce((acc, cur) => acc + cur, 0)},
            {id: "sirvemaquina", name:'No sirvió la máquina', value: !pizzaPortal ? 0 : pizzaPortal.nosirviomaquina.reduce((acc, cur) => acc + cur, 0)},
            {id: "ordenlista", name:'Mi orden no estaba lista en la máquina', value: !pizzaPortal ? 0 : pizzaPortal.orden_nolista.reduce((acc, cur) => acc + cur, 0)},
            {id: "equivocada", name:'Se asignó el pedido a una sucursal equivocada', value: !pizzaPortal ? 0 : pizzaPortal.sucursal_equivocada.reduce((acc, cur) => acc + cur, 0)},
        ])

        setDataProduct([
            {id: "temperatura", name:'Temperatura', value: !pizzaPortal ? 0 : pizzaPortal.temperatura.reduce((acc, cur) => acc + cur, 0)},
            {id: "sabor", name:'Sabor', value: !pizzaPortal ? 0 : pizzaPortal.sabor.reduce((acc, cur) => acc + cur, 0)},
            {id: "presentacion", name:'Presentación', value: !pizzaPortal ? 0 : pizzaPortal.presentacion.reduce((acc, cur) => acc + cur, 0)},
        ])

        setDataService([
            {id: "atencion", name:'Atención personal', value: !pizzaPortal ? 0 : pizzaPortal.atencion_personal.reduce((acc, cur) => acc + cur, 0)},
            {id: "sucursal-sucia", name:'Sucursal sucia', value: !pizzaPortal ? 0 : pizzaPortal.sucursal_sucia.reduce((acc, cur) => acc + cur, 0)},
        ])

        setDataOther([
            {id: "other", name:'Otros', value: !pizzaPortal ? 0 : pizzaPortal.otros.reduce((acc, cur) => acc + cur, 0)},
        ])

        setDataTicket([
            {id: "problema-fac", name:'Problemas para facturar', value: !pizzaPortal ? 0 : pizzaPortal?.facturacion_dificultad.reduce((acc, cur) => acc + cur, 0)},
            {id: "no-ticket", name:'No recibi Ticket', value: !pizzaPortal ? 0 : pizzaPortal?.facturacion_norecibi.reduce((acc, cur) => acc + cur, 0)},
        ])

    }, [pizzaPortal]);

    useEffect(() => {
        fullDataSorted();
    }, [dataPizzaPortal])
    
    const fullDataSorted = () => {
        const problemOrderingSorted = dataProblemOrdering.sort((a,b) => b.value - a.value);
        setDataProblemOrderingSort(problemOrderingSorted)

        const problemPickingUpOrderSorted = dataProblemPickingUpOrder.sort((a,b) => b.value - a.value);
        setdataProblemPickingUpOrderSort(problemPickingUpOrderSorted)

        const problemProductSorted = dataProduct.sort((a,b) => b.value - a.value);
        setDataProductSort(problemProductSorted)

        const problemServiceSorted = dataService.sort((a,b) => b.value - a.value);
        setDataServiceSort(problemServiceSorted)

        const problemOtherSorted = dataOther.sort((a,b) => b.value - a.value);
        setDataOtherSort(problemOtherSorted)

        const problemTicketSorted=dataTicket.sort((a,b) => b.value - a.value);
        setDataTicketSort(problemTicketSorted)
    }

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Problema al realizar pedido</p>
                    <ProblemOrderingTable
                        dataProblemOrderingSort={dataProblemOrderingSort}
                    />
                </Col>

                <Col xs={12} md={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Problema al recoger mi pedido</p>
                    <ProblemPickingOrderTable 
                        dataProblemPickingUpOrderSort={dataProblemPickingUpOrderSort}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Producto</p>
                    <Product 
                        dataProductSort={dataProductSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Servicio</p>
                    <ServiceTable 
                        dataServiceSort={dataServiceSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Ticket y facturación</p>
                    <TicketTable 
                        dataTicketsSort={dataTicketSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Otro</p>
                    <OtherTable 
                        dataOtherSort={dataOtherSort}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DoughnutCharts;
