import React, { useContext, useState } from 'react';
import { Collapse, Table, Row, Col } from 'reactstrap';
import { Line } from 'react-chartjs-2'
import { contextApp } from '../../../../App/contextApp';
import Loading from '../../../../loading/Loading';


const ProblemPickingUpOrder = (props) => {
const [collapse, setCollapse] = useState(false);
const { monthsLabel, dataIncidences, texts } = props;
const {ProductosPresentation, ProductosSabor, ProductosTemperatura, pizzaportal} = dataIncidences;
const toggle = () => setCollapse(!collapse);

const {loading, collapseToSS} = useContext(contextApp);

const getnofuncionaqr = () => {
    if (!pizzaportal?.nofuncionaqr) return

    const { nofuncionaqr } = pizzaportal

    var out = nofuncionaqr;
    if(nofuncionaqr){
        if(nofuncionaqr.length === 1) {
            // out = [null, nofuncionaqr[0], null];
            out = [nofuncionaqr[0]];
        }
    }
    return out;
}
const getordenincorrecta = () => {
    if (!pizzaportal?.orden_incorrecta) return

    const { orden_incorrecta } = pizzaportal

    var out = orden_incorrecta;
    if(orden_incorrecta){
        if(orden_incorrecta.length === 1) {
            // out = [null, orden_incorrecta[0], null];
            out = [orden_incorrecta[0]];
        }
    }
    return out;
}
const getnosirviomaquina = () => {
    if (!pizzaportal?.nosirviomaquina) return

    const { nosirviomaquina } = pizzaportal

    var out = nosirviomaquina;
    if(nosirviomaquina){
        if(nosirviomaquina.length === 1) {
            // out = [null, nosirviomaquina[0], null];
            out = [nosirviomaquina[0]];
        }
    }
    return out;
}
const getordennolista = () => {
    if (!pizzaportal?.orden_nolista) return

    const { orden_nolista } = pizzaportal

    var out = orden_nolista;
    if(orden_nolista){
        if(orden_nolista.length === 1) {
            // out = [null, orden_nolista[0], null];
            out = [orden_nolista[0]];
        }
    }
    return out;
}
const getsucursalequivocada = () => {
    if (!pizzaportal?.sucursal_equivocada) return

    const { sucursal_equivocada } = pizzaportal

    var out = sucursal_equivocada;
    if(sucursal_equivocada){
        if(sucursal_equivocada.length === 1) {
            // out = [null, sucursal_equivocada[0], null];
            out = [sucursal_equivocada[0]];
        }
    }
    return out;
}

const getMonthsLabel = () => {
    var out = monthsLabel;
    if(monthsLabel.length === 1) {
        out = ['nulo', monthsLabel[0], 'nulo' ];
    }
    return out;
}

const data = {
    labels: getMonthsLabel(),
    datasets:[
        {
            label: texts.noSucursal,
            fill: false,
            lineTension: 0,
            backgroundColor: '#ef3d3d',
            borderColor: '#ef3d3d',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ef3d3d',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ef3d3d',
            pointHoverBorderColor: '#ef3d3d',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 10,
            data: getnofuncionaqr()
    
        },{
            label: texts.paymentMethodsProblems,
            fill: false,
            lineTension: 0,
            backgroundColor: '#ef3db4',
            borderColor: '#ef3db4',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ef3db4',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ef3db4',
            pointHoverBorderColor: '#ef3db4',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 10,
            data: getordenincorrecta()
    
        },{
            label: texts.noMachineOrder,
            fill: false,
            lineTension: 0,
            backgroundColor: '#bf3def',
            borderColor: '#bf3def',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#bf3def',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#bf3def',
            pointHoverBorderColor: '#bf3def',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 10,
            data: getnosirviomaquina()
    
        },{
            label: texts.orderConfirmation,
            fill: false,
            lineTension: 0,
            backgroundColor: '#783def',
            borderColor: '#783def',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#783def',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#783def',
            pointHoverBorderColor: '#783def',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 10,
            data: getordennolista()
    
        },{
            label: texts.cancelModifyOrder,
            fill: false,
            lineTension: 0,
            backgroundColor: '#3d6cef',
            borderColor: '#3d6cef',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#3d6cef',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#3d6cef',
            pointHoverBorderColor: '#3d6cef',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 10,
            data: getsucursalequivocada()
        }


]
}
return (
<div>
    <div className='collapseHeader' onClick={toggle}>  
        <h4 className='emigre-bold text-secondary text-left text-white text-uppercase'>
            <i className="icomoon icon-producto text-white ml-3 p-1"></i> {texts.problemPickingUpOrder} <span><svg height="30" width="30" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={collapse ? "css-6q0nyr-Svg" : "css-6q0nyr-Svg closed"}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
        </h4>
    </div>
    <Collapse isOpen={collapse || collapseToSS}>
    {loading ? (
        <Loading />
        ) : null}
        <Row>
            <Col xs={12} className='text-center'>
                <p className="d-inline mx-3 text-center text-uppercase">No funciona el QR<span className="legend badge-box__problem-picking-up-order-noqr ml-2"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">Me entregaron orden incorrecta<span className="legend badge-box__problem-picking-up-order-incorrecta ml-2"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">No sirvió la máquina<span className="legend badge-box__problem-picking-up-order-sirvemaquina ml-2"></span></p>
            </Col>
        </Row>
        <Row>
        <Col xs={12} className='text-center'>
                <p className="d-inline mx-3 text-center text-uppercase">Mi orden no estaba lista en la máquina<span className="legend badge-box__problem-picking-up-order-ordennolista ml-2"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">Se asignó el pedido a una sucursal equivocada<span className="legend badge-box__problem-picking-up-order-equivocada ml-2"></span></p>
                <Line data={data} legend={{display:false}}></Line>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th scope="row" className="bg-secondary text-uppercase">PROBLEMA AL RECOGER MI PEDIDO</th>
                            {monthsLabel && monthsLabel.map((item, index) => {
                                return <th className="text-center bg-secondary" key={index}>{item}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                            <td className="text-uppercase">No funciona el QR</td>
                            {getnofuncionaqr() && getnofuncionaqr().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">Me entregaron orden incorrecta</td>
                            {getordenincorrecta() && getordenincorrecta().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">No sirvió la máquina</td>
                            {getnosirviomaquina() && getnosirviomaquina().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">Mi orden no estaba lista en la máquina</td>
                            {getordennolista() && getordennolista().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">Se asignó el pedido a una sucursal equivocada</td>
                            {getsucursalequivocada() && getsucursalequivocada().map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Collapse>
</div>
);
}

export default ProblemPickingUpOrder;