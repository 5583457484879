import React, { useState, useEffect, useContext } from "react"
import {  Row, Col, Card, Badge, CardBody, CardTitle } from 'reactstrap'

const CardsURD = ({ dataIncidences }) => {  

    const { delivery } = dataIncidences
    console.log(delivery);
    let totalIncidents
    if(delivery){
        totalIncidents = Object.entries(delivery)
            .map(([_,e]) => e)
            .map((f) => f.reduce((acc,cur) => (acc+cur), 0))
            .reduce((acc,cur) => acc+cur, 0);
    }
    const getTotalUber = () => {
        if (!delivery) return
        // const totalCurrentMonth = Object.entries(delivery)
        //     .filter(([d,e]) => d.includes('uber'))
        //     .map(([_,f]) => f)
        //     .reduce((acc, curr) => acc + curr[curr.length - 1], 0);

        // console.log(.map(([_,f]) => f).reduce((acc, cur) => acc + cur, 0) );

        let totalUber=0
        Object.entries(delivery).filter(([d,e]) => d.includes('uber')).map(([_,f]) => {
            totalUber+=f.reduce((acc, cur) => acc + cur, 0)
        })
        let totalUberPercent = totalIncidents !== 0 ? parseFloat((totalUber * 100) / totalIncidents).toFixed(1) : 0
        
        // const totalPrevMonth = Object.entries(delivery)
        //     .filter(([d,e]) => d.includes('uber'))
        //     .map(([_,f]) => f)
        //     .reduce((acc, curr) => acc + curr[curr.length - 2], 0);

        //     const totalPercentUber = getComparison(totalCurrentMonth, totalPrevMonth)
        
        return {
            totalUber,
            totalUberPercent
        };
    }

    const getTotalRappi = () => {
        if (!delivery) return
        // const totalCurrentMonth = Object.entries(delivery)
        //     .filter(([d,e]) => d.includes('rappi'))
        //     .map(([_,f]) => f)
        //     .reduce((acc, curr) => acc + curr[curr.length - 1], 0);

        // const totalPrevMonth = Object.entries(delivery)
        //     .filter(([d,e]) => d.includes('rappi'))
        //     .map(([_,f]) => f)
        //     .reduce((acc, curr) => acc + curr[curr.length - 2], 0);

        //     const totalPercentRappi = getComparison(totalCurrentMonth, totalPrevMonth)

        let totalRappi=0
        Object.entries(delivery).filter(([d,e]) => d.includes('rappi')).map(([_,f]) => {
            totalRappi+=f.reduce((acc, cur) => acc + cur, 0)
        })
        let totalRappiPercent = totalIncidents !== 0 ? parseFloat((totalRappi * 100) / totalIncidents).toFixed(1) : 0
        
            return {
                totalRappi,
                totalRappiPercent
            };
    }

    const getTotalDidi = () => {
        if (!delivery) return
        // const totalCurrentMonth = Object.entries(delivery)
        //     .filter(([d,e]) => d.includes('didi'))
        //     .map(([_,f]) => f)
        //     .reduce((acc, curr) => acc + curr[curr.length - 1], 0);

        // const totalPrevMonth = Object.entries(delivery)
        //     .filter(([d,e]) => d.includes('didi'))
        //     .map(([_,f]) => f)
        //     .reduce((acc, curr) => acc + curr[curr.length - 2], 0);

        //     const totalPercentDidi = getComparison(totalCurrentMonth, totalPrevMonth)
        
        let totalDidi=0
        Object.entries(delivery).filter(([d,e]) => d.includes('didi')).map(([_,f]) => {
            totalDidi+=f.reduce((acc, cur) => acc + cur, 0)
        })
        let totalDidiPercent = totalIncidents !== 0 ? parseFloat((totalDidi * 100) / totalIncidents).toFixed(1) : 0
        return {
            totalDidi,
            totalDidiPercent
            };
    }

    const getComparison = (currentMonth, prevMonth) => {
        if (prevMonth === 0) return 0
        if (currentMonth < prevMonth) return parseFloat(((currentMonth * 100) / prevMonth) - 100).toFixed(1)
        if (currentMonth > prevMonth) return parseFloat(((currentMonth * 100) / prevMonth) - 100).toFixed(1)
    }

  return (
    <Row className="mb-4">
            <Col xs={12}>
                <h4 className="emigre-bold text-secondary text-uppercase">TOTAL DE QUEJAS POR DELIVERY</h4>
            </Col>
            <Col xs={12} md={4}>
                <Card className="p-3">
                    <CardTitle align="center">
                        <p className="m-0 text-uppercase">UBER EATS</p>
                    </CardTitle>
                    <CardBody>
                        <h3 className="text-center">{delivery ? getTotalUber().totalUber : 0} <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line">{delivery ? getTotalUber().totalUberPercent + "%" : "0%"}</Badge></h3>
                    </CardBody>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="p-3">
                    <CardTitle align="center">
                        <p className="m-0 text-uppercase">RAPPI</p>
                    </CardTitle>
                    <CardBody>
                        <h3 className="text-center">{delivery ? getTotalRappi().totalRappi : 0} <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line">{delivery ? getTotalRappi().totalRappiPercent + "%" : "0%"}</Badge></h3>
                        
                    </CardBody>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="p-3">
                    <CardTitle align="center">
                        <p className="m-0 text-uppercase">DIDI FOOD</p>
                    </CardTitle>
                    <CardBody>
                        <h3 className="text-center ">{delivery ? getTotalDidi().totalDidi : 0} <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line">{delivery ? getTotalDidi().totalDidiPercent + "%" : "0%"}</Badge></h3>
                        
                    </CardBody>
                </Card>
            </Col>
        </Row> 
  )
}

export default CardsURD