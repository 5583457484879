import React, { useContext } from "react";

import {
  BarChart,
  Bar as BarRe,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import { contextApp } from "../../../../App/contextApp";
import Loading from "../../../../loading/Loading";

const ChartOfComplaintsWithoutCommentsByStatus = React.memo(({ graphicsSum }) => {
  
    const {
      sincomentario_nuevas,
      sincomentario_contestando_crm,
      sincomentario_contestando_fb,
      sincomentario_cerrada_fb,
    } = graphicsSum;

    const {loading} = useContext(contextApp)

    return (
      <div
        style={{
          width: "100%",
          height: 700,
          position: "relative",
        }}
      >
        {loading ? (
          <Loading />
        ) : null}
        <ResponsiveContainer>
          <BarChart
            data={[
              {
                name: "Nuevas Status",
                "Sin Asignar": sincomentario_nuevas,
              },
              {
                name: "Contestando",
                "Contestando por CRM": sincomentario_contestando_crm,
                "Contestando por Facebook": sincomentario_contestando_fb,
              },
              {
                name: "Cerradas",
                "Cerrada por Facebook": sincomentario_cerrada_fb,
              },
            ]}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Legend
              verticalAlign="top"
              height={36}
              align="center"
              iconType="circle"
              iconSize={20}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, (dataMax) => Math.ceil(dataMax * 1.3)]} />
            <Tooltip
              cursor={{ opacity: "0.2" }}
              wrapperStyle={{ outline: "none" }}
              contentStyle={{
                border: "2px solid #ff7534",
                borderRadius: "10px",
              }}
              labelStyle={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            />
            <BarRe
              isAnimationActive={false}
              dataKey="Sin Asignar"
              fill="#574c41"
              minPointSize={5}
            >
              <LabelList
                dataKey="Sin Asignar"
                position="top"
                // content={renderCustomizedLabel}
              />
            </BarRe>
            <BarRe
              isAnimationActive={false}
              dataKey="Contestando por CRM"
              fill="#e36b6b"
              minPointSize={5}
            >
              <LabelList
                dataKey="Contestando por CRM"
                // content={renderCustomizedLabel}
                position="top"
              />
            </BarRe>
            <BarRe
              isAnimationActive={false}
              dataKey="Contestando por Facebook"
              fill="#e3a56b"
              minPointSize={5}
            >
              <LabelList
                dataKey="Contestando por Facebook"
                // content={renderCustomizedLabel}
                position="top"
              />
            </BarRe>
            <BarRe
              isAnimationActive={false}
              dataKey="Cerrada por Facebook"
              fill="#e3c77b"
              minPointSize={5}
            >
              <LabelList
                dataKey="Cerrada por Facebook"
                // content={renderCustomizedLabel}
                position="top"
              />
            </BarRe>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

export default ChartOfComplaintsWithoutCommentsByStatus;
