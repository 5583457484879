import React, { useContext } from "react";

import {
  BarChart,
  Bar as BarRe,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import { contextApp } from "../../../../App/contextApp";
import Loading from "../../../../loading/Loading";

const ChartOfComplaintsWithCommentsByStatus = React.memo(
  ({ graphicsSum }) => {
    const {
      comentario_nuevas,
      comentario_contestando_crm,
      comentario_contestando_fb,
      comentario_cerradas_crm,
      comentario_cerradas_fb_crm,
      comentario_cerradas_fb_usuario,
    } = graphicsSum;

    const {loading} = useContext(contextApp)

    return (
      <div style={{ width: "100%", height: 700 }}>
        {loading ? (
          <Loading />
        ) : null}
        <ResponsiveContainer>
          <BarChart
            data={[
              {
                name: "Nuevas Status",
                "Sin Asignar": comentario_nuevas,
              },
              {
                name: "Contestando",
                CRM: comentario_contestando_crm,
                Facebook: comentario_contestando_fb,
              },
              {
                name: "Cerradas",
                CRM: comentario_cerradas_crm,
                "Facebook Inactividad de CRM": comentario_cerradas_fb_crm,
                "Facebook Inactividad de Usuario":
                  comentario_cerradas_fb_usuario,
              },
            ]}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Legend
              verticalAlign="top"
              height={36}
              align="center"
              iconType="circle"
              iconSize={20}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, (dataMax) => Math.floor(dataMax * 1.1)]} />
            <Tooltip
              cursor={{ opacity: "0.2" }}
              wrapperStyle={{ outline: "none" }}
              contentStyle={{
                border: "2px solid #ff7534",
                borderRadius: "5px",
              }}
              labelStyle={{
                textAlign: "center",
                fontWeight: "bold",
              }}
              itemStyle={{
                fontWeight: "bolder",
              }}
            />
            <BarRe
              isAnimationActive={false}
              dataKey="Sin Asignar"
              fill="#574c41"
              minPointSize={5}
            >
              <LabelList
                dataKey="Sin Asignar"
                position="top"
                // content={renderCustomizedLabel}
              />
            </BarRe>
            <BarRe
              isAnimationActive={false}
              dataKey="CRM"
              fill="#e36b6b"
              minPointSize={5}
            >
              <LabelList
                dataKey="CRM"
                // content={renderCustomizedLabel}
                position="top"
              />
            </BarRe>
            <BarRe
              isAnimationActive={false}
              dataKey="Facebook"
              fill="#e3a56b"
              minPointSize={5}
            >
              <LabelList
                dataKey="Facebook"
                // content={renderCustomizedLabel}
                position="top"
              />
            </BarRe>
            <BarRe
              isAnimationActive={false}
              dataKey="Facebook Inactividad de CRM"
              fill="#e3c77b"
              minPointSize={5}
            >
              <LabelList
                dataKey="Facebook Inactividad de CRM"
                // content={renderCustomizedLabel}
                position="top"
              />
            </BarRe>
            <BarRe
              isAnimationActive={false}
              dataKey="Facebook Inactividad de Usuario"
              fill="#96875a"
              minPointSize={5}
            >
              <LabelList
                dataKey="Facebook Inactividad de Usuario"
                // content={renderCustomizedLabel}
                position="top"
              />
            </BarRe>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

export default ChartOfComplaintsWithCommentsByStatus;
