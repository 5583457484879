import React, { useContext } from "react"
import { Pie } from 'react-chartjs-2'
import { Row, Col } from 'reactstrap'
import './pieChart.scss';
import { contextApp } from "../../../../App/contextApp";

const PieChart = (props) =>{
    const { dataCategoriesResume, texts, dataIncidences } = props;
    const { delivery } = dataIncidences;

    const { badgeBoxColors } = useContext(contextApp);

    const {
        badgeBoxProduct,
        badgeBoxTime,
        badgeBoxOther,
        badgeBoxTicket 
    } = badgeBoxColors;

    let data = {};

    let totalIncidents;

    let totalProductIncidents;
    let totalPercentProductIncidents;

    let totalTimeIncidents;
    let totalPercentTimeIncidents;

    let totalTicketIncidents;
    let totalPercentTicketIncidents;

    let totalOtherIncidents;
    let totalPercentOtherIncidents;

    if (delivery) {
        
        totalIncidents = Object.entries(delivery)
            .map(([_,e]) => e)
            .map((f) => f.reduce((acc,cur) => (acc+cur), 0))
            .reduce((acc,cur) => acc+cur, 0);

        totalProductIncidents = Object.entries(delivery)
            .map(([e, f]) => {
                if(e === "didi_temperatura") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "rappi_temperatura") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "uber_temperatura") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "didi_sabor") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "rappi_sabor") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "uber_sabor") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "didi_presentacion") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "rappi_presentacion") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "uber_presentacion") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "didi_orden_incorrecta") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "rappi_orden_incorrecta") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "uber_orden_incorrecta") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentProductIncidents = totalIncidents !== 0 ? parseFloat((totalProductIncidents * 100) / totalIncidents).toFixed(1) : 0

        totalTimeIncidents = Object.entries(delivery)
            .map(([e, f]) => {
                if(e === "didi_tiempo_40") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "rappi_tiempo_40") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "uber_tiempo_40") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "didi_tiempo_1hr") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "rappi_tiempo_1hr") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "uber_tiempo_1hr") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentTimeIncidents = totalIncidents !== 0 ? parseFloat((totalTimeIncidents * 100) / totalIncidents).toFixed(1) : 0
        
        totalTicketIncidents = Object.entries(delivery)
        .map(([e, f]) => {
            if(e === "didi_dificultad") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            if(e === "didi_norecibi") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            if(e === "rappi_dificultad") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            if(e === "rappi_norecibi") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            if(e === "uber_dificultad") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
            if(e === "uber_norecibi") {
                return f.reduce((acc, cur) => acc+cur, 0)
            }
        })
        .reduce((acc,cur) => {
            if (typeof cur === 'number') return acc+cur
            return acc;
        }, 0)

        totalPercentTicketIncidents = totalIncidents !== 0 ? parseFloat((totalTicketIncidents * 100) / totalIncidents).toFixed(1) : 0
        
        totalOtherIncidents = Object.entries(delivery)
            .map(([e, f]) => {
                if(e === "didi_otros") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "rappi_otros") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "uber_otros") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentOtherIncidents = totalIncidents !== 0 ? parseFloat((totalOtherIncidents * 100) / totalIncidents).toFixed(1) : 0

        data = {
                labels: [texts?.product, texts?.time, texts?.ticket ,texts?.other],
                datasets: [
                    {  
                        label: '%',
                        backgroundColor: [
                            badgeBoxProduct,
                            badgeBoxTime,
                            badgeBoxTicket,
                            badgeBoxOther 
                        ],
                        data: [
                            totalPercentProductIncidents,
                            totalPercentTimeIncidents,
                            totalPercentTicketIncidents,
                            totalPercentOtherIncidents
                        ],
                    }
                ] 
            }

    }
    
    return( <>
                <Row className="mb-4">
                    <Col xs={12} className="text-center mb-4">
                        <div className="d-flex justify-content-md-center justify-content-end align-items-center"><h5 className="mr-2">{texts.totalIncidents}</h5><span className="grayCapsule w-auto ml-1 d-inline-block">{!totalIncidents ? 0 : totalIncidents}</span></div>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="text-right left-bar">
                        <p className="align-items-center d-flex justify-content-end"><span>{texts.product}</span> <div className="mx-2"><span className="legend badge-box__product "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentProductIncidents}%</span></div></p>
                        <p className="align-items-center number formatext d-flex justify-content-end"><span>{texts.time}</span> <div className="mx-2"><span className="legend badge-box__time "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentTimeIncidents}%</span></div></p>
                        <p className="align-items-center number formatext d-flex justify-content-end"><span>{texts.ticket}</span> <div className="mx-2"><span className="legend badge-box__ticket "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentTicketIncidents}%</span></div></p>
                        <p className="align-items-center d-flex justify-content-end"><span>{texts.other}</span> <div className="mx-2"><span className="legend badge-box__other"></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentOtherIncidents}%</span></div></p>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="d-flex align-items-center">
                        {
                            totalIncidents !== 0 ?
                            <Pie data={data} legend={{display:false}}></Pie> :
                            <h2 className="text-center">No hay datos disponibles</h2>
                        }
                        
                    </Col>
                </Row>
            </>
        )
}

export default PieChart;