import React,  { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Input, Button, Card, CardBody, CardTitle, FormFeedback, FormGroup, CardText } from 'reactstrap';
import { contextApp } from '../../App/contextApp';
import { ToastContainer, toast } from 'react-toastify';
import HttpHandlerServices from '../../../services/httpService';

import logo from '../../../assets/img/logo-orange.png';
import './Login.scss';

import language from './language.json';


const Login = (props) => {

    let canSend = true;

    const {setUserSession, currentLanguage} = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

      

    },[currentLanguage])
    
    
    const [dataUser, setDataUser] = useState({
        username: '',
        password: ''
    })
    const [validate, setValidate] = useState({
        username: false,
        password: false
    })
    const getData = (e) =>{
        setDataUser({...dataUser, [e.target.name]: e.target.value})
        setValidate({ ...validate, [e.target.name]: false });
    }

    const onSubmit = () =>{

        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if (!re.test(String(dataUser.username))) {
          
            setValidate({ ...validate.username = true });

            canSend = false;
        
        }
        
        if(dataUser.password === ""){
        
            setValidate({ ...validate, password: true });
        
            canSend = false;
        
        }

        if(canSend){
        
            const data = {
        
                email: dataUser.username,
        
                password: dataUser.password,
        
                remember_me: true
        
            }
        
            login(data);
       
        }

    }

    const login = (data) => {

        const httpHandlerServices = new HttpHandlerServices();

        const endpoint = '/login';

        httpHandlerServices.postMethod(endpoint, data).then(

            async(res) => {

                

                const data = await res;

                if(data) {
                    switch(data.code) {

                        case 99:

                            toast.warning(`USUARIO O CONTRASEÑA INVALIDO Por favor verifique sus credenciales`, {

                                position: toast.POSITION.BOTTOM_RIGHT,

                                autoClose: 8000

                            });

                          break;

                        default:

                            localStorage.setItem('userSession', JSON.stringify(data));

                            setUserSession(JSON.parse(localStorage.getItem('userSession')));
                            
                            props.history.push(`/home`);
                            
                      }
                }
                

            }, 
            async(error) => {

                const err = await error;
                toast.error(`${err}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 15000
                });
                

            }

        )

    }

    return (
        <Container className="login">
            <ToastContainer />
            <Row>
                <Col>
                    <Card className="mt-5 p-3">
                        <CardTitle align="center">
                            <img src={logo} className="img-fluid mb-3 mt-5" alt="Little Caesars"/>
                            <h4 className="text-dark emigre-bold text-uppercase">{texts?.welcome}</h4>
                            <p className="text-secondary">{texts?.ctaUserPass}</p>
                        </CardTitle>
                        <CardBody>
                            <FormGroup>
                                <Input 
                                type="email" 
                                name="username" 
                                id="username" 
                                placeholder={texts?.emailPlaceHolder}
                                className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                                onChange={(e) => getData(e)} invalid={validate.username}/>
                                <FormFeedback color="danger" className="my-2 text-center">
                                    <span className="text-white bg-danger rounded-circle mr-1">i</span> 
                                    {texts?.invalidEmailMessage}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-5">
                                <Input 
                                type="password" 
                                name="password" 
                                id="pass" 
                                placeholder={texts?.passwordPlaceHolder}
                                className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" 
                                onChange={(e) => getData(e)} 
                                invalid={validate.password}
                                onKeyDown={
                                    (e)=> {
                                        console.log(e.key)
                                        if(e.key === 'Enter'){
                                            onSubmit()
                                        }
                                    }
                                }
                                
                                />
                                <FormFeedback color="danger" className="my-2 text-center">
                                    <span className="text-white bg-danger rounded-circle mr-1">i</span>
                                    {texts?.invalidPasswordMessage}
                                </FormFeedback>
                            </FormGroup>
                        </CardBody>
                        <CardText align="center">
                            <a 
                            href="/recover-password" 
                            target="_blank" 
                            className="text-primary emigre-bold text-uppercase text-center d-block mb-4">
                                {texts?.forgotPasswordMessage}
                            </a>
                            <Button 
                            color="primary" 
                            className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" 
                            size="lg" 
                            onClick={onSubmit}>
                                {texts?.ctaLogin}
                            </Button>
                        </CardText>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

}

export default Login;