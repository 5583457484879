import React , {useState} from 'react'
import './NewPassword.scss'
import logo from '../../../assets/img/logo-orange.png';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Input, CardText, Button, FormFeedback } from 'reactstrap'
import HttpHandlerServices from '../../../services/httpService'

const NewPassword = (props) => {

    let canSend = true;

    const [dataUser, setDataUser] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword:''
    });

    const [validate, setValidate] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    const getData = (e) =>{
        setDataUser({...dataUser, [e.target.name]: e.target.value})
        setValidate({ ...validate, [e.target.name]: false });
    }

    const onSubmit = () =>{

        
        if (dataUser.currentPassword !== '') {

            if (dataUser.newPassword === dataUser.confirmPassword && dataUser.newPassword !== '') {

                canSend = true;

            } else {

                setValidate({ ...validate, newPassword: true, confirmPassword: true});
                canSend = false;

            }

        } else {
            setValidate({ ...validate, currentPassword: true});
            canSend = false;
        }
    

        if(canSend){
            const data = {
                password_old: dataUser.currentPassword,
                password_new: dataUser.newPassword,
            }

            const httpHandlerServices = new HttpHandlerServices();

            const endPoint = '/changepassword';

            httpHandlerServices.postMethod(endPoint, data).then(
                async res => {
                    const data = await res;
            
                    if(data) {
                        switch(data.code) {
                            case 102:

                                toast.warning(`CONTRASEÑA INVALIDO Por favor verifique sus credenciales`, {

                                    position: toast.POSITION.BOTTOM_RIGHT,
    
                                    autoClose: 8000
    
                                });
    
                                break;
                            
                            default:
                                
                                    toast.warning(`PASSWORD ACTUALIZADO DE FORMA CORRECTA POR FAVOR VUELTA EN UNOS SEGUNDOS SERA REDIRECCIONADO`, {

                                        position: toast.POSITION.BOTTOM_RIGHT,
                
                                        autoClose: 8000
                
                                    });

                                    setTimeout(() => {
                                        props.history.push(`/home`);
                                    }, 4000);

                                    
                        }
                    }
                    
                }, async error => {
                    const data = await error;
                    if(data) {
                        toast.error(`SE PRODUJO UN ERROR AL INTENTAR CAMBIAR EL PASSWORD`, {
    
                            position: toast.POSITION.BOTTOM_RIGHT,
    
                            autoClose: 8000
    
                        });
                    }
                }
            );
        }

    }

    return(
    <Container className="login">
        <ToastContainer />
            <Row>
                <Col>
                    <Card className="mt-5 p-3">
                        <CardTitle align="center">
                            <img src={logo} className="img-fluid mb-3 mt-5" alt="Little Caesars"/>
                            <h4 className="text-dark emigre-bold text-uppercase">cambio de contraseña</h4>
                            <p className="text-secondary">Ingresa tu nueva contraseña</p>
                        </CardTitle>
                        <CardBody>
                            <FormGroup>
                                <Input type="password" name="currentPassword" id="currentPassword" placeholder="Contraseña actual" className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" onChange={(e) => getData(e)} invalid={validate.currentPassword}/>
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span>Campo Requerido</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Input type="password" name="newPassword" id="newPassword" placeholder="Nueva contraseña" className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" onChange={(e) => getData(e)} invalid={validate.newPassword}/>
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span>Campo requerido</FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-5">
                                <Input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirmar contraseña" className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold" onChange={(e) => getData(e)} invalid={validate.confirmPassword}/>
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span>Las contraseñas no coinciden</FormFeedback>
                            </FormGroup>
                        </CardBody>
                        <CardText align="center">
                            <Button color="primary" className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" size="lg" onClick={onSubmit}>CAMBIAR CONTRASEÑA</Button>
                        </CardText>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

}


export default NewPassword;