import React, { useContext } from "react"
import { Pie } from 'react-chartjs-2'
import { Row, Col } from 'reactstrap'
import './pieChart.scss';
import { contextApp } from "../../../../App/contextApp";

const PieChart = (props) =>{
    const { dataCategoriesResume, texts, dataIncidences } = props;
    const { pizzaportal } = dataIncidences;

    const { badgeBoxColors } = useContext(contextApp);

    const {
        badgeBoxProblemOrdering,
        badgeBoxProblemPickingUpOrder,
        badgeBoxProduct,
        badgeBoxService,
        badgeBoxTicket,
        badgeBoxOther 
    } = badgeBoxColors;

    let data = {};

    let totalIncidents;

    let totalProblemOrderingIncidents;
    let totalPercentProblemOrderingIncidents;

    let totalProblemPickingUpOrderIncidents;
    let totalPercentProblemPickingUpOrderIncidents;

    let totalProductIncidents;
    let totalPercentProductIncidents;

    let totalServiceIncidents;
    let totalPercentServiceIncidents;

    let totalTicketIncidents;
    let totalPercentTicketIncidents;


    let totalOtherIncidents;
    let totalPercentOtherIncidents;

    if (pizzaportal) {
        
        totalIncidents = Object.entries(pizzaportal)
            .map(([_,e]) => e)
            .map((f) => f.reduce((acc,cur) => (acc+cur), 0))
            .reduce((acc,cur) => acc+cur, 0);

        totalProblemOrderingIncidents = Object.entries(pizzaportal)
            .map(([e, f]) => {
                if(e === "appnosirve") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "nosucursal") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "problemapago") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "nopuderealizar") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "nollegoconfirmacion") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "nomodificapedido") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentProblemOrderingIncidents = totalIncidents !== 0 ? parseFloat((totalProblemOrderingIncidents * 100) / totalIncidents).toFixed(1) : 0

        totalProblemPickingUpOrderIncidents = Object.entries(pizzaportal)
            .map(([e, f]) => {
                if(e === "nofuncionaqr") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "orden_incorrecta") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "nosirviomaquina") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "orden_nolista") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "sucursal_equivocada") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentProblemPickingUpOrderIncidents = totalIncidents !== 0 ? parseFloat((totalProblemPickingUpOrderIncidents * 100) / totalIncidents).toFixed(1) : 0

        totalProductIncidents = Object.entries(pizzaportal)
            .map(([e, f]) => {
                if(e === "temperatura") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "sabor") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "presentacion") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentProductIncidents = totalIncidents !== 0 ? parseFloat((totalProductIncidents * 100) / totalIncidents).toFixed(1) : 0

        totalServiceIncidents = Object.entries(pizzaportal)
            .map(([e, f]) => {
                if(e === "atencion_personal") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
                if(e === "sucursal_sucia") {
                    return f.reduce((acc, cur) => acc+cur, 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentServiceIncidents = totalIncidents !== 0 ? parseFloat((totalServiceIncidents * 100) / totalIncidents).toFixed(1) : 0
        
        totalTicketIncidents= Object.entries(pizzaportal)
        .map(([e, f]) => {
            if(e === "facturacion_dificultad") {
                return f.reduce((acc, cur) => acc+cur , 0)
            }
            if(e === "facturacion_norecibi") {
                return f.reduce((acc, cur) => acc+cur , 0)
            }
        })
        .reduce((acc,cur) => {
            if (typeof cur === 'number') return acc+cur
            return acc;
        }, 0)

        totalPercentTicketIncidents=totalIncidents !== 0 ? parseFloat((totalTicketIncidents * 100) / totalIncidents).toFixed(1) : 0

        totalOtherIncidents = Object.entries(pizzaportal)
            .map(([e, f]) => {
                if(e === "otros") {
                    return f.reduce((acc, cur) => acc+cur , 0)
                }
            })
            .reduce((acc,cur) => {
                if (typeof cur === 'number') return acc+cur
                return acc;
            }, 0)

        totalPercentOtherIncidents = totalIncidents !== 0 ? parseFloat((totalOtherIncidents * 100) / totalIncidents).toFixed(1) : 0

        data = {
                labels: [texts?.problemOrdering, texts?.problemPickingOrder, texts?.product, texts?.service,texts?.ticket, texts?.other],
                datasets: [
                    {  
                        label: '%',
                        backgroundColor: [
                            badgeBoxProblemOrdering,
                            badgeBoxProblemPickingUpOrder,
                            badgeBoxProduct,
                            badgeBoxService,
                            badgeBoxTicket,
                            badgeBoxOther
                        ],
                        data: [
                            totalPercentProblemOrderingIncidents,
                            totalPercentProblemPickingUpOrderIncidents,
                            totalPercentProductIncidents,
                            totalPercentServiceIncidents,
                            totalPercentTicketIncidents,
                            totalPercentOtherIncidents
                        ],
                    }
                ] 
            }

    }
    
    return( <>
                <Row className="mb-4">
                    <Col xs={12} className="text-center mb-4">
                        <div className="d-flex justify-content-md-center justify-content-end align-items-center"><h5 className="mr-2">{texts.totalIncidents}</h5><span className="grayCapsule w-auto ml-1 d-inline-block">{!totalIncidents ? 0 : totalIncidents}</span></div>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="text-right left-bar">
                        <p className="number formatext d-flex justify-content-end align-items-center"><span>{texts.problemOrdering}</span> <div className="mx-2"><span className="legend badge-box__problem-ordering"></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentProblemOrderingIncidents}%</span></div></p>
                        <p className="number formatext d-flex justify-content-end align-items-center"><span>{texts.problemPickingOrder}</span> <div className="mx-2"><span className="legend badge-box__problem-picking-up-order"></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentProblemPickingUpOrderIncidents}%</span></div></p>
                        <p className="d-flex justify-content-end align-items-center"><span>{texts.product}</span> <div className="mx-2"><span className="legend badge-box__product "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentProductIncidents}%</span></div></p>
                        <p className="number formatext d-flex justify-content-end align-items-center"><span>{texts.service}</span> <div className="mx-2"><span className="legend badge-box__service "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentServiceIncidents}%</span></div></p>
                        <p className="number formatext d-flex justify-content-end align-items-center"><span>{texts.ticket}</span> <div className="mx-2"><span className="legend badge-box__ticket "></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentTicketIncidents}%</span></div></p>
                        <p className="d-flex justify-content-end align-items-center"><span>{texts.other}</span> <div className="mx-2"><span className="legend badge-box__other"></span></div><div><span className="grayCapsule w-auto ml-1 d-inline-block">{totalPercentOtherIncidents}%</span></div></p>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="d-flex align-items-center">
                        {
                            totalIncidents !== 0 ?
                            <Pie data={data} legend={{display:false}}></Pie> :
                            <h2 className="text-center">No hay datos disponibles</h2>
                        }
                        
                    </Col>
                </Row>
            </>
        )
}

export default PieChart;