import React, { useContext } from "react";

import Period from "../../Period/Period";
import HistoricalTotal from "../../HistoricalTotal/HistoricalTotal";
import IncidencesByCategory from "../../IncidencesByCategory/IncidencesByCategory";
import PercentCategories from "../../PercentCategories/PercentCategories";
import ComplaintDetail from "../../ComplaintDetails/ComplaintDetail";
import { contextApp } from "../../../../App/contextApp";
import PurchaseChannel from "../../PurchaseChannel/PurchaseChannel";

const GeneralStatistics = ({
  dataGeneral,
  dataCategoriesResume,
  dataIncidences,
  dataCategories,
}) => {

  const { loading } = useContext(contextApp)

  return (
    <>
    <HistoricalTotal
        dataGeneral={dataGeneral}
        dataCategoriesResume={dataCategoriesResume}
        dataIncidences={dataIncidences}
      />
      <Period dataGeneral={dataGeneral} />
      <PurchaseChannel dataGeneral={dataGeneral} dataIncidences={dataIncidences}/>
{/*       <IncidencesByCategory
        dataIncidences={dataIncidences}
        dataCategories={dataCategories}
        // dataCategoriesResume={dataCategoriesResume}
      />
      <PercentCategories
        dataCategoriesResume={dataCategoriesResume}
        dataCategories={dataCategories}
      />
      <ComplaintDetail
        dataIncidences={dataIncidences}
        // dataCategoriesResume={dataCategoriesResume}
      /> */}
    </>
  );
};

export default GeneralStatistics;
