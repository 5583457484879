import React, { useEffect, useState } from "react";
import { mostradorReason,delivery,pizzaPortalReason } from "./reasons";
import "./SelectChange.scss";



export function SelectChangeReason(props) {
  const { channel,categorie,reason,setReason } = props;
  const {
    dataMostradorCalidadProducto,
    dataMostradorCalidadServicio,
    dataMostradorTiempo,
    dataMostradorTicketFacturacion
  } = mostradorReason();

  const {
    dataPizzaPedido,
    dataPizzaProducto,
    dataPizzaRecoger,
    dataPizzaServicio,
    dataPizzaTicketFacturacion
  }=pizzaPortalReason()

  const {
    dataDeliveryProducto,
    dataDeliveryTicketFacturacion,
    dataDeliveryTiempo
  } = delivery()
 
  const [option, setOption] = useState(false);
  const [label, setLabel] = useState("-")
  const [data, setData] = useState([])
  

  

  const func = () => {
    setOption(!option);
  };

  const setChange = (value) => {
    setReason(value);
    setOption(!option);
  };

  const nameLabel = () => {
    data.map((option) => {
      if (option.value === reason.value) {
        setLabel(option.label);
        return;
      }
    });
  };

  const closeforce=()=>{
    if(option){
      setOption(!option);
    }
    return true
  }

  useEffect(() => {
    if(categorie){
      if(channel.value=="mostrador"){
        if(categorie.value=="calidad_de_servicio") setData(dataMostradorCalidadServicio)
        if(categorie.value=="calidad_de_producto") setData(dataMostradorCalidadProducto)
        if(categorie.value=="tiempo_de_entrega") setData(dataMostradorTiempo)
        if(categorie.value=="ticket_y_facturacion") setData(dataMostradorTicketFacturacion)
      }else if(channel.value=="pizzaportal"){
        if(categorie.value=="calidad_de_servicio") setData(dataPizzaServicio)
        if(categorie.value=="calidad_de_producto") setData(dataPizzaProducto)
        if(categorie.value=="ticket_y_facturacion") setData(dataPizzaTicketFacturacion)
        if(categorie.value=="pedido") setData(dataPizzaPedido)
        if(categorie.value=="recogerpedido") setData(dataPizzaRecoger)
      }else{
        if(categorie.value=="producto") setData(dataDeliveryProducto)
        if(categorie.value=="tiempo") setData(dataDeliveryTiempo)
        if(categorie.value=="ticket_y_facturacion") setData(dataDeliveryTicketFacturacion)
        
      }
      
    }
  }, [categorie])
  
  useEffect(() => {
    if (reason) {
      nameLabel();
    } else {
      setLabel("-");
    }
  }, [reason]);



  return (
    <>
      <p className="modalchange-title">RAZÓN</p>
      <button className="select-container" onClick={func} disabled={categorie?false:closeforce()}>
        <p>{label}</p>
        <button  className="icon-select" disabled={categorie?false:closeforce()}>
          {option ? (
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9588 7.82064L7.49934 1.44473L1.03984 7.82064C0.924426 7.93479 0.769497 7.99869 0.608168 7.99869C0.446839 7.99869 0.291911 7.93479 0.176501 7.82064C0.12062 7.7652 0.076225 7.69902 0.0459179 7.62599C0.0156107 7.55296 0 7.47454 0 7.39532C0 7.3161 0.0156107 7.23768 0.0459179 7.16464C0.076225 7.09161 0.12062 7.02544 0.176501 6.97L7.04829 0.18577C7.16896 0.0666614 7.33082 0 7.49934 0C7.66786 0 7.82971 0.0666614 7.95039 0.18577L14.8222 6.96869C14.8785 7.02417 14.9232 7.09051 14.9537 7.16378C14.9843 7.23706 15 7.31578 15 7.39532C15 7.47485 14.9843 7.55358 14.9537 7.62686C14.9232 7.70013 14.8785 7.76647 14.8222 7.82195C14.7068 7.9361 14.5518 8 14.3905 8C14.2292 8 14.0743 7.9361 13.9588 7.82195V7.82064Z"
                fill="#F3723C"
              />
            </svg>
          ) : categorie ? (
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9588 0.179359L7.49934 6.55527L1.03984 0.179359C0.924426 0.0652126 0.769497 0.00130859 0.608168 0.00130859C0.446839 0.00130859 0.291911 0.0652126 0.176501 0.179359C0.12062 0.234801 0.076225 0.300975 0.0459179 0.374008C0.0156107 0.447041 0 0.525463 0 0.604682C0 0.6839 0.0156107 0.762322 0.0459179 0.835355C0.076225 0.908388 0.12062 0.974563 0.176501 1.03L7.04829 7.81423C7.16896 7.93334 7.33082 8 7.49934 8C7.66786 8 7.82971 7.93334 7.95039 7.81423L14.8222 1.03131C14.8785 0.97583 14.9232 0.909493 14.9537 0.836218C14.9843 0.762943 15 0.684216 15 0.604682C15 0.525148 14.9843 0.44642 14.9537 0.373145C14.9232 0.29987 14.8785 0.233533 14.8222 0.17805C14.7068 0.0639034 14.5518 0 14.3905 0C14.2292 0 14.0743 0.0639034 13.9588 0.17805V0.179359Z"
                fill="#F3723C"
              />
            </svg>
          ):(
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9588 0.179359L7.49934 6.55527L1.03984 0.179359C0.924426 0.0652126 0.769497 0.00130859 0.608168 0.00130859C0.446839 0.00130859 0.291911 0.0652126 0.176501 0.179359C0.12062 0.234801 0.076225 0.300975 0.0459179 0.374008C0.0156107 0.447041 0 0.525463 0 0.604682C0 0.6839 0.0156107 0.762322 0.0459179 0.835355C0.076225 0.908388 0.12062 0.974563 0.176501 1.03L7.04829 7.81423C7.16896 7.93334 7.33082 8 7.49934 8C7.66786 8 7.82971 7.93334 7.95039 7.81423L14.8222 1.03131C14.8785 0.97583 14.9232 0.909493 14.9537 0.836218C14.9843 0.762943 15 0.684216 15 0.604682C15 0.525148 14.9843 0.44642 14.9537 0.373145C14.9232 0.29987 14.8785 0.233533 14.8222 0.17805C14.7068 0.0639034 14.5518 0 14.3905 0C14.2292 0 14.0743 0.0639034 13.9588 0.17805V0.179359Z"
                fill="#D3D3D3"
              />
            </svg>
            
          )}
        </button>
        {option && (
          <div className="options">
            {data.map((option, index) => (
              <button
                key={index}
                className="button-option"
                onClick={() => setChange(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </button>
    </>
  );
}
