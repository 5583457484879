import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Badge } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2'
import TimeTable from './tables/TimeTable'
import Product from './tables/ProductTable'
import ServiceTable from './tables/ServiceTable'
import OtherTable from './tables/OtherTable'
import TicketTable from './tables/TicketTable'
import './DougnutCharts.scss'

const DoughnutCharts = (props) => {
    const { product, service, time, other, texts, mostrador} = props;

    const [dataMostrador, setDataMostrador] = useState([])

    const [dataProduct, setDataProduct] = useState([])
    const [dataService, setDataService] = useState([])
    const [dataTime, setDataTime] = useState([])
    const [dataOther, setDataOther] = useState([])
    const [dataTicket, setDataTicket] = useState([])

    const [dataProductSort, setDataProductSort] = useState([])
    const [dataServiceSort, setDataServiceSort] = useState([])
    const [dataTimeSort, setDataTimeSort] = useState([])
    const [dataOtherSort, setDataOtherSort] = useState([])
    const [dataTicketSort, setDataTicketSort] = useState([])

    useEffect(() => {

        setDataMostrador(mostrador)

        setDataProduct([
            {id: "temperatura", name:'Temperatura', value: !mostrador ? 0 : mostrador.ProductosTemperatura.reduce((acc, cur) => acc + cur, 0)},
            {id: "sabor", name:'Sabor', value: !mostrador ? 0 : mostrador.ProductosSabor.reduce((acc, cur) => acc + cur, 0)},
            {id: "presentacion", name:'Presentación', value: !mostrador ? 0 : mostrador.ProductosPresentation.reduce((acc, cur) => acc + cur, 0)},
        ])

        setDataService([
            {id: "orden-incorrecta", name:'Orden incorrecta', value: !mostrador ? 0 : mostrador.CalidadOrden.reduce((acc, cur) => acc + cur, 0)},
            {id: "sucursal-sucia", name:'Sucursal sucia', value: !mostrador ? 0 : mostrador.CalidadSucursal.reduce((acc, cur) => acc + cur, 0)},
            {id: "atencion", name:'Atención de personal', value: !mostrador ? 0 : mostrador.CalidadAtencion.reduce((acc, cur) => acc + cur, 0)},
        ])

        // setDataTime([
        //     {id: "mas-5", name:'Más de 5 min', value: !mostrador ? 0 :  mostrador.Tiempo_5fila.reduce((acc, cur) => acc + cur, 0) + mostrador.Tiempo_5sinfila.reduce((acc, cur) => acc + cur, 0)},
        //     {id: "mas-10", name:'Más de 10 min', value: !mostrador ? 0 : mostrador.Tiempo_10fila.reduce((acc, cur) => acc + cur, 0) + mostrador.Tiempo_10sinfila.reduce((acc, cur) => acc + cur, 0)},
        //     {id: "mas-15", name:'Más de 15 min', value: !mostrador ? 0 : mostrador.Tiempo_15fila.reduce((acc, cur) => acc + cur, 0) + mostrador.Tiempo_15sinfila.reduce((acc, cur) => acc + cur, 0)},
        // ])

        setDataTime([
            {id: "mas-5", name:'Más de 5 minutos', value: !mostrador ? 0 :  mostrador.Tiempo_5fila.reduce((acc, cur) => acc + cur, 0)},
            {id: "mas-10", name:'Más de 10 minutos', value: !mostrador ? 0 : mostrador.Tiempo_10fila.reduce((acc, cur) => acc + cur, 0)},
            {id: "mas-15", name:'Más de 15 minutos', value: !mostrador ? 0 : mostrador.Tiempo_15fila.reduce((acc, cur) => acc + cur, 0)},
        ])

        setDataOther([
            {id: "other", name:'Otro', value: !mostrador ? 0 : mostrador?.Otros.reduce((acc, cur) => acc + cur, 0)},
        ])

        setDataTicket([
            {id: "problema-fac", name:'Problemas para facturar', value: !mostrador ? 0 : mostrador?.Dificultad.reduce((acc, cur) => acc + cur, 0)},
            {id: "no-ticket", name:'No recibi Ticket', value: !mostrador ? 0 : mostrador?.NoRecibi.reduce((acc, cur) => acc + cur, 0)},
        ])

    }, [mostrador]);

    useEffect(() => {
        fullDataSorted();
    }, [dataMostrador])
    
    const fullDataSorted = () => {

        const problemProductSorted = dataProduct.sort((a,b) => b.value - a.value);
        setDataProductSort(problemProductSorted)

        const problemServiceSorted = dataService.sort((a,b) => b.value - a.value);
        setDataServiceSort(problemServiceSorted)

        const problemTimeSorted = dataTime.sort((a,b) => b.value - a.value);
        setDataTimeSort(problemTimeSorted)

        const problemOtherSorted = dataOther.sort((a,b) => b.value - a.value);
        setDataOtherSort(problemOtherSorted)

        const problemTicket=dataTicket.sort((a,b) => b.value - a.value);
        setDataTicketSort(problemTicket)
    }
   
    return (
        <>
            <Row>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Producto</p>
                    <Product 
                        dataProductSort={dataProductSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Servicio</p>
                    <ServiceTable 
                        dataServiceSort={dataServiceSort}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Tiempo</p>
                    <TimeTable 
                        dataTimeSort={dataTimeSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Ticket y facturación</p>
                    <TicketTable 
                        dataTicketsSort={dataTicketSort}
                    />
                </Col>
                <Col xs={12} md={4} lg={6}>
                    <p className='text-uppercase text-center font-weight-bold'>Otro</p>
                    <OtherTable 
                        dataOtherSort={dataOtherSort}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DoughnutCharts;
