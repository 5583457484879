import React from 'react'
import { Row, Col, Table, Badge } from 'reactstrap'

const OtherTable = ({ dataOtherSort }) => {
  return (
    <Table className="dougnut-charts-table__table">
        <tbody>
           {dataOtherSort.map(({ name, value, id }) => (
            <tr key={id}>
              <td className="dougnut-charts-table__td-no-borders text-right border-right">
                <tr>
                  <td className="dougnut-charts-table__td-title">
                    {name}
                  </td>
                  <td
                    className="dougnut-charts-table__td-value align-middle"
                    style={{ width: "10%" }}
                  >
                    <div
                    // className='problem-other-box'
                    className={`badge-box__other-${id}`}
                    />
                  </td>
                </tr>
              </td>
              <td className="align-middle">{value}</td>
            </tr>
          ))} 
        </tbody>
      </Table>
  )
}

export default OtherTable