import React, { useContext, useState } from 'react';
import { Collapse, Table, Row, Col  } from 'reactstrap';
import { Line } from 'react-chartjs-2'
import Loading from '../../../../loading/Loading';
import { contextApp } from '../../../../App/contextApp';

const Otro = (props) => {
    const [collapse, setCollapse] = useState(false);
    const { monthsLabel, dataIncidences, texts } = props;
    const {pizzaportal} = dataIncidences;

const {loading, collapseToSS} = useContext(contextApp);

const toggle = () => setCollapse(!collapse);

const getOther = () => {
    if (!pizzaportal?.otros) return
        var out = pizzaportal.otros;
        if(pizzaportal.otros){
            if(pizzaportal.otros.length === 1) {
                // out = [null, pizzaportal.otros[0], null];
                out = [pizzaportal.otros[0]];
            }
        }
        return out;
}

const getMonthsLabel = () => {
    var out = monthsLabel;
    if(monthsLabel.length === 1) {
        out = ['nulo', monthsLabel[0], 'nulo' ];
    }
    return out;
}

const data = {
    labels: getMonthsLabel(),
    datasets:[{
        label: "Otro",
        fill: false,
        lineTension: 0,
        backgroundColor: '#170cf3',
        borderColor: '#170cf3',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#170cf3',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#170cf3',
        pointHoverBorderColor: '#170cf3',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 20,
        data: getOther()

    }

]
}

return (
    <div>
        <div className='collapseHeader' onClick={toggle}>  
            <h4 className='emigre-bold text-secondary text-left text-white text-uppercase'>
                <i className="icomoon icon-servicio text-white ml-3 p-1"></i> Otro <span><svg height="30" width="30" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={collapse ? "css-6q0nyr-Svg" : "css-6q0nyr-Svg closed"}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
            </h4>
        </div>
        <Collapse isOpen={collapse || collapseToSS}>
        {loading ? (
            <Loading />
            ) : null}
            <Row>
                <Col xs={12} className='text-center'>
                    <p className="d-inline mx-3 text-center text-uppercase">Otro<span className="legend badge-box__other-other ml-2"></span></p>
                    <Line data={data} legend={{display:false}}></Line>
                </Col>
    
            </Row>
            <Row>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th scope="row" className="bg-secondary text-uppercase">Otro</th>
                                {monthsLabel && monthsLabel.map((item, index) => {
                                    return <th className="text-center bg-secondary" key={index}>{item}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-uppercase">Otro</td>
                                {getOther() && getOther().map((item, index) => {
                                return <td className="text-center" key={index}>{item}</td>
                                })}
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Collapse>
    </div>
    );
}

export default Otro;